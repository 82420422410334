import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import './App.css'
import UserLogin from './components/Auth/UserLogin'
import { Routes, BrowserRouter, Route } from 'react-router-dom'
import PasswordReset from './components/Auth/PasswordReset'
import UserDashboard from './components/page/UserDashboard'
import SearchLinkPage from './components/page/SearchLinkPage'
import AdminDashboard from './components/component/adminDashboard/AdminDashboard'
import SetPassword from './components/Auth/SetPassword'
import TokenValidation from './components/Auth/TokenValidation'
import Home from './components/LandingPage/Home'
import UserSignup from './components/Auth/UserSignup'
import Products from './components/Products/Products'
import ContactUs from './components/Contacts/Contact'
import PasswordTokenValidation from './components/Auth/PasswordTokenValidation'
import ChangePassword from './components/Auth/ChangeYourPassword'


const App = () => {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home></Home>} />
          <Route path='/user-login' element={<UserLogin />} />
          <Route path='/admin-dashboard' element={<AdminDashboard />} />
          <Route path='/User-Signup' element={<UserSignup />} />
          <Route path='/Reset-Password' element={<PasswordReset></PasswordReset>} />
          <Route path='/set-Password/:token/:email' element={<SetPassword></SetPassword>} />
          <Route path='/change-Password/:token/:email' element={<ChangePassword/>} />
          <Route path='/token-validation/:token/:email' element={<TokenValidation />} />
          <Route path='/password-token-validation/:token/:email' element={<PasswordTokenValidation />} />
          <Route path='/user-dashboard/:req_email_id' element={<UserDashboard></UserDashboard>} />
          <Route path='/user-profile/:emailIdeParams' element={<SearchLinkPage />} />
          <Route path='/products' element={<Products />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/password-reset' element={<PasswordReset />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App