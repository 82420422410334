import { Typography } from "@mui/material";
import React from "react";
import EmailIcon from '@mui/icons-material/Email';
import { FaBars, FaFacebookF, FaHome, FaInstagram, FaPhone, FaShoppingCart, FaTwitterSquare, FaWhatsapp, FaYoutubeSquare } from "react-icons/fa";
import logo1 from '../../image/lionlogo.jpeg'
import logo2 from '../../image/lions_club.png'
import axios from 'axios'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Swal from "sweetalert2";


const Products = () => {

    const [customerEmail, setCustomerEmail] = useState()
    const [customerMobile, setCustomerMobile] = useState()
    const [name, setName] = useState()
    const [pass1, setPass1] = useState()
    const [pass, setPass] = useState()
    const [isOtp, setIsOtp] = useState(false)
    const [price, setPrice] = useState()
    const [product, setProdut] = useState()
    const [salerEmail, setSalerEmail] = useState()
    const [productName, setProductName] = useState()
    const [genPass, setGenPass] = useState(false)

    const navigate = useNavigate()

    const [productData, setProductData] = useState([])

    const products = () => {
        axios.post('https://lionsapi.emedha.in/product/show-all').then((res) => {
            setProductData(res.data)
        })
    }

    useEffect(() => {
        products()
    }, [])

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 270 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div style={{ backgroundColor: 'rgb(53, 53, 111)', paddingTop: 10, paddingBottom: 15, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', paddingRight: 29, borderRadius: '0.0003rem' }} className="card"  >
                <img src={logo2} style={{ width: '54px', marginLeft: 14, marginTop: 10 }} />
                <Typography style={{ marginTop: 7 }} >Welcome, <br /> Lions International</Typography>
            </div>
            <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate('/')} >
                        <FaHome style={{ marginRight: 10, color: 'rgb(53, 53, 111)' }} />
                        <ListItemText style={{ color: 'rgb(53, 53, 111)' }} primary='Home' />
                    </ListItemButton>
                </ListItem>
            </List>

            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate('/products')}>
                        <FaShoppingCart style={{ marginRight: 10, color: 'rgb(53, 53, 111)' }} />
                        <ListItemText style={{ color: 'rgb(53, 53, 111)' }} primary='Products' />
                    </ListItemButton>
                </ListItem>
            </List>
            <div style={{ position: 'absolute', bottom: 0, width: '100%', borderTop: '1px solid rgb(53, 53, 111)', paddingTop: 10, paddingBottom: 23 }} >
                <Typography style={{ marginBottom: 0, color: 'rgb(53, 53, 111)', fontSize: 11.3, marginTop: 13, marginLeft: 4 }}>@2023 Emedha technologies all right registered.</Typography>
            </div>
        </Box>
    );

    const [aboutProduct, setAboutProduct] = useState([])

    const moreAboutProduct = (id) => {

        axios.post('https://lionsapi.emedha.in/product/about-more', {
            id
        }).then((res) => {
            setAboutProduct(res.data)
            handleClickOpen1()
            setProdut(res.data[0].productPhoto)
            setProductName(res.data[0].productName)
            setPrice(res.data[0].productPrice)
            setSalerEmail(res.data[0].UserId)
        })
    }

    const moreAboutProduct1 = (id) => {

        axios.post('https://lionsapi.emedha.in/product/about-more', {
            id
        }).then((res) => {
            handleClickOpen2()
            setProdut(res.data[0].productPhoto)
            setProductName(res.data[0].productName)
            setPrice(res.data[0].productPrice)
            setSalerEmail(res.data[0].UserId)
        })
    }


    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };


    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };


    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleClose3 = () => {
        setOpen3(false);
    };

    const [isMember, setIsMember] = useState(true)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const loginFailedAlert = () => {
        Swal.fire({

            title: "Warning !",
            text: "Please enter valied login password, your email or password wrong !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok"
        }).then((result) => {
            if (result.value) {
                return
            }
        })
    }

    const loginSuccessfullAlert = () => {
        Swal.fire({
            title: "Successfull !",
            text: "You are login successfullly",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok"
        }).then((result) => {
            if (result.value) {
                moreAboutProduct(id)
            } else {
                moreAboutProduct(id)
            }
        })
    }

    const memberData = (email) => {
        axios.post('https://lionsapi.emedha.in/profile', {
            email
        }).then((res) => {
            sessionStorage.setItem('guestLogin', res.data[0].email)
            sessionStorage.setItem('customerName', res.data[0].name)
            sessionStorage.setItem('customerMobile', res.data[0].mobile)
            loginSuccessfullAlert()
            sessionStorage.setItem('userEmail', email)
            handleClose2()
            profile()
        })
    }

    const userLoginData = () => {
        axios
            .post('https://lionsapi.emedha.in/login', {
                email,
                password,
            }).then((response) => {
                if (response.data.status === 200) {
                    memberData(email)
                } else {
                    handleClose2()
                    loginFailedAlert()
                }
            })
    }

    const [id, setId] = useState()

    const checkAuthenticate = (id) => {
        setId(id)
        if (!sessionStorage.getItem('guestLogin')) {
            moreAboutProduct1(id)
        } else {
            moreAboutProduct(id)
        }
    }

    const guestLogin = () => {
        setGenPass(true)
        axios
            .post('https://lionsapi.emedha.in/product/guest-login', {
                customerEmail,
                name,
                customerMobile
            }).then((response) => {
                if (response.data.status === 200) {
                    setIsOtp(true)
                    setGenPass(false)
                    setPass1(response.data.password)
                } else {
                    handleClose2()
                    loginFailedAlert()
                    setGenPass(false)
                }
            })
    }

    const referralAlert = () => {
        Swal.fire({
            title: "Successfull !",
            text: "Referral sent successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok"
        }).then((result) => {
            if (result.value) {
                moreAboutProduct(id)
            } else {
                moreAboutProduct(id)
            }
        })
    }

    const [isRef, setIsRef] = useState(false)

    const productReferral = () => {

        setIsRef(true)
        axios
            .post('https://lionsapi.emedha.in/product/product-referral', {
                customerEmail: sessionStorage.getItem('guestLogin'),
                customerName: sessionStorage.getItem('customerName'),
                customerMobile: sessionStorage.getItem('customerMobile'),
                salerEmail: salerEmail,
                price,
                name: productName,
                product
            }).then((response) => {
                if (response.data.status === 200) {
                    setIsRef(false)
                    handleClose1()
                    handleClose2()
                    handleClose3()
                    referralAlert()
                }
            })
    }

    const passvalidation = () => {
        if (pass1 !== pass) {
            alert('Login Failed')
        } else {
            sessionStorage.setItem('guestLogin', customerEmail)
            sessionStorage.setItem('customerName', name)
            sessionStorage.setItem('customerMobile', customerMobile)
            loginSuccessfullAlert()
            profile()
            handleClose2()
        }
    }


    const [salerMobile, setSalerMobile] = useState()
    const [salerName, setSalerName] = useState()

    const profile = (email = salerEmail) => {
        axios.post('https://lionsapi.emedha.in/profile', {
            email
        }).then((res) => {
            setSalerName(res.data[0].name)
            setSalerMobile(res.data[0].mobile)
        })
    }


    return (
        <div>
            <div className='navbar3 card' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 5, paddingRight: 5, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', backgroundColor: 'rgb(53, 53, 111)' }} >
                <Typography style={{ fontSize: 13, color: 'white' }} ><EmailIcon style={{ marginTop: '-0.16rem' }} />  lbn320c@gmail.com</Typography>

                <Typography className="d-none d-sm-flex" style={{ fontSize: 17, color: 'white', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>

                <a href="https://api.whatsapp.com/send?phone=+918187094345&text= Hi District 320c" target="_blank">
                    <button style={{ border: 'none', background: 'rgb(20, 170, 20)', height: '1.8rem', width: '6.8rem', borderRadius: '0.2rem', color: 'white' }}><FaWhatsapp style={{ fontSize: '1rem', marginTop: '-0.12rem' }} /> Whatsapp</button>
                </a>
            </div>
            <div className='navbar3 card card d-none d-sm-flex' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
                <img src={logo2} style={{ width: '56px' }} />
                <div className="card d-none d-sm-flex" style={{ width: '70%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} >
                    <div className="card" style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '15rem', border: 'none' }}>
                        <Typography onClick={() => navigate('/')} style={{ color: 'rgb(53, 53, 111)', cursor: 'pointer' }}>
                            Home
                        </Typography>
                        <Typography style={{ color: 'white', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.9rem', padding: 3, paddingLeft: 12, paddingRight: 12 }}>
                            Products
                        </Typography>
                        <Typography onClick={() => navigate('/contact-us')} style={{ color: 'rgb(53, 53, 111)', cursor: 'pointer' }}>
                            Contacts US
                        </Typography>
                    </div>
                    <button onClick={() => navigate('/user-login')} className="getButton1" >
                        Login/Signup
                    </button>
                </div>
                <img src={logo1} style={{ width: '56px' }} />
            </div>
            <div className='navbar3 card card d-sm-none d-flex' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >

                <React.Fragment>

                    <FaBars onClick={toggleDrawer('left', true)} style={{ fontSize: '1.2rem', marginLeft: '0.22rem' }} />
                    <SwipeableDrawer
                        anchor={'left'}
                        open={state["left"]}
                        onClose={toggleDrawer('left', false)}
                        onOpen={toggleDrawer('left', true)}
                    >
                        {list('left')}
                    </SwipeableDrawer>
                </React.Fragment>
                <Typography className="text-center" style={{ fontSize: 17, color: 'rgb(53, 53, 111)', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>
                <img src={logo1} style={{ width: '36px', marginRight: '0.22rem' }} />
            </div>
            <div className='navbar3 card card d-sm-none d-flex' style={{ borderRadius: '0.0001rem', backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
                <button className="getButton2 w-50" >
                    Contact Us
                </button>
                <button onClick={() => navigate('/user-login')} className="getButton1 w-50" >
                    Login/Signup
                </button>
            </div>
            <section>
                <div className="container-fluid pb-5 shadow ">
                    <div className="container">
                        <Typography style={{ fontSize: '1.9rem', fontWeight: 'bold', color: 'rgb(53, 53, 111)', marginTop: '3rem' }} >
                            All Products
                            <div style={{ borderTop: '4px solid rgb(53, 53, 111)', width: '2.6rem', marginTop: '-0.2rem' }} ></div>
                        </Typography>
                        <br />

                        <div className="row gy-3" >
                            {
                                productData.map((data) => {
                                    return (
                                        <div className="col-12 col-md-10 col-lg-3">
                                            <article class="card1 card " style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ height: '24rem', overflow: 'hidden' }} >
                                                    <img
                                                        class="card1__background"
                                                        src={data.productPhoto}
                                                        alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                                                    />
                                                </div>
                                                <div class="card1__content | flow" style={{ width: '19.4rem' }} >
                                                    <div class="card1__content--container | flow">
                                                        <h2 class="card1__title" style={{ fontSize: '1.5rem' }} >{data.productName}</h2>
                                                        <p class="card1__description">
                                                            {data.productDesc}
                                                        </p>
                                                    </div>
                                                    <button class="card1__button" style={{ borderRadius: '0.2rem' }} onClick={() => checkAuthenticate(data.id)}>More About</button>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog
                        open={open1}
                        onClose={handleClose1}
                        aria-labelledby="alert-dialog-title"
                        maxWidth='xl'
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Use Google's location service?"}
                        </DialogTitle>
                        <DialogContent style={{ width: '78rem', height: '43rem' }} >
                            <div className="row" >
                                {
                                    aboutProduct.map((data) => {
                                        return (
                                            <div className="row">
                                                <div className="col-lg-5 ">
                                                    <div style={{ borderRight: '1.7px solid lightgray', padding: 23, padding: 58, borderTop: '1.7px solid lightgray', borderBottom: '1.7px solid lightgray', borderTopRightRadius: '0.2rem', borderBottomRightRadius: '0.2rem' }} >
                                                        <img src={data.productPhoto} style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6" style={{ marginTop: '-0.4rem' }} >
                                                    <Typography style={{ color: 'rgb(53, 53, 111)', fontSize: 23, fontWeight: 'bold' }} >Product Details</Typography>
                                                    <div style={{ borderTop: '4px solid rgb(53, 53, 111)', width: '4rem', marginTop: '-0.2rem' }} ></div>
                                                    <br />
                                                    <div>
                                                        <Typography style={{ fontSize: 19, fontWeight: 'bold', color: 'black' }}>Product: {data.productName}</Typography>
                                                        <Typography style={{ fontSize: 14, color: 'black' }}>Price: {data.productPrice}</Typography>
                                                        <Typography style={{ fontSize: 14, color: 'black' }}>Description: {data.productDesc}</Typography>
                                                        <br />
                                                        <Typography style={{ color: 'rgb(53, 53, 111)', fontSize: 18, fontWeight: 'bold' }} >Referral</Typography>
                                                        <div style={{ borderTop: '4px solid rgb(53, 53, 111)', width: '2.3rem', marginTop: '-0.2rem' }} ></div>
                                                        <br />
                                                        <button onClick={() => {
                                                            productReferral()
                                                        }} disabled={isRef} style={{ border: 'none', background: !isRef ? 'rgb(172, 55, 55)' : 'lightgray', height: '1.8rem', width: '6.8rem', borderRadius: '0.2rem', color: 'white', marginRight: 3 }}><EmailIcon style={{ fontSize: '1rem', marginTop: '-0.12rem' }} /> Email</button>

                                                        <a onClick={() => {
                                                            handleClose1()
                                                            handleClose2()
                                                            handleClose3()
                                                        }} href={`https://api.whatsapp.com/send?phone=${salerMobile}&text= Dear ${salerName}, You have got a referral from ${sessionStorage.getItem('customerName')} product name ${productName} Product image link: ${product}, Customer Contact Information, Mobile Number: ${sessionStorage.getItem('customerMobile')} And Email ${sessionStorage.getItem('guestLogin')}`} target="_blank">
                                                            <button style={{ border: 'none', background: 'rgb(20, 170, 20)', height: '1.8rem', width: '6.8rem', borderRadius: '0.2rem', color: 'white' }}><FaWhatsapp style={{ fontSize: '1rem', marginTop: '-0.12rem' }} /> Whatsapp</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose1} style={{ backgroundColor: 'red', color: 'white', fontSize: '0.8rem' }}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={open2}
                        onClose={handleClose2}
                        maxWidth='lg'
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div style={{ padding: 10 }}>
                            <Typography style={{ fontSize: 17, fontWeight: 'bold', color: 'rgb(53, 53, 111)', marginBottom: 12, marginTop: 17 }} >Login</Typography>
                            <Box sx={{ marginBottom: 2 }} >
                                <Button onClick={() => {
                                    setIsMember(true)
                                }} className='shadow' style={{ borderRadius: '0.001rem', background: isMember ? 'rgb(53, 53, 111)' : 'white', color: isMember ? 'white' : 'rgb(53, 53, 111)', width: '50%' }} >Member</Button>
                                <Button onClick={() => {
                                    setIsMember(false)
                                }} className='shadow' style={{ borderRadius: '0.001rem', background: !isMember ? 'rgb(53, 53, 111)' : 'white', color: !isMember ? 'white' : 'rgb(53, 53, 111)', width: '50%' }} >Non Member</Button>
                            </Box>
                            <br />
                            {
                                isMember && (
                                    <div style={{ width: '34rem' }} >
                                        <input onChange={(e) => setEmail(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter Your email" />
                                        <br></br>
                                        <br />
                                        <input onChange={(e) => setPassword(e.target.value)} type="password" style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enater your password" />
                                    </div>
                                )
                            }
                            {
                                (!isMember && !isOtp) && (
                                    <div style={{ width: '34rem' }} >
                                        <input onChange={(e) => setName(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter Your name" />
                                        <br></br>
                                        <br />
                                        <input onChange={(e) => setCustomerEmail(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter your email" />
                                        <br></br>
                                        <br />
                                        <input onChange={(e) => setCustomerMobile(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter your mobile" />
                                        <br></br>
                                        <br />
                                    </div>
                                )
                            }
                            {
                                (!isMember && isOtp) && (
                                    <div style={{ width: '34rem' }} >
                                        <input type="password" onChange={(e) => setPass(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter Your password" />
                                        <br />
                                        <br />
                                        <Typography style={{ padding: 10, borderRadius: '0.2rem', backgroundColor: 'green', color: 'white' }} >Password send on your login email please check your email and fallow geven instructions</Typography>
                                        <br></br>
                                        <br />
                                    </div>
                                )
                            }
                        </div>
                        {
                            isMember && (
                                <DialogActions>
                                    <Button style={{ background: 'red', color: 'white' }} onClick={handleClose2}>Cancel</Button>
                                    <Button onClick={() => userLoginData()} style={{ background: 'rgb(53, 53, 111)', color: 'white' }} autoFocus>
                                        Login
                                    </Button>
                                </DialogActions>
                            )
                        }
                        {
                            (!isMember && !isOtp) && (
                                <DialogActions>
                                    <Button style={{ background: 'red', color: 'white' }} onClick={handleClose2}>Cancel</Button>
                                    <Button disabled={genPass} onClick={() => guestLogin()} style={{ background: !genPass ? 'rgb(53, 53, 111)' : 'lightgray', color: 'white' }} autoFocus>
                                        Generate Password
                                    </Button>
                                </DialogActions>
                            )
                        }

                        {
                            (!isMember && isOtp) && (
                                <DialogActions>
                                    <Button style={{ background: 'red', color: 'white' }} onClick={handleClose2}>Cancel</Button>
                                    <Button onClick={() => passvalidation()} style={{ background: 'rgb(53, 53, 111)', color: 'white' }} autoFocus>
                                        Login
                                    </Button>
                                </DialogActions>
                            )
                        }
                    </Dialog>
                </div>
            </section>
            <div className='card' style={{ justifyContent: 'center', alignItems: 'center', padding: 10, border: 'none', marginTop: 28, backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
                <div className='card' style={{ width: '10rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
                    <FaInstagram size={24} color='#fff'></FaInstagram>
                    <FaFacebookF size={24} color='#fff'></FaFacebookF>
                    <FaYoutubeSquare size={24} color='#fff'></FaYoutubeSquare>
                    <FaTwitterSquare size={24} color='#fff'></FaTwitterSquare>
                </div>
                <div style={{ width: '13rem', backgroundColor: 'white', height: '0.14rem', marginTop: 10 }} >
                </div>
                <Typography style={{ marginBottom: 10, color: 'white', fontSize: 14 }}>@2023 Emedha technologies all right registered.</Typography>
            </div>
        </div>
    )
}

export default Products