import React, { useEffect, useState } from 'react'
import { FaFacebook, FaFacebookF, FaInstagram, FaPhone, FaTwitterSquare, FaYoutubeSquare, FaHome, FaShoppingCart, FaBars, FaWhatsapp } from 'react-icons/fa'
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import logo1 from '../../image/lionlogo.jpeg'
import logo2 from '../../image/lions_club.png'
import EmailIcon from '@mui/icons-material/Email';
import RegisterPageSlider from '../RegisterPageSlider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ReCAPTCHA from "react-google-recaptcha";

const UserLoginPage = () => {

  const [value, setValue] = useState(false)

  function onChange(value) {
    if (value) {
      setValue(true)
    } else {
      setValue(false)
    }
  }

  const navigate = useNavigate()

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [login, setLogin] = useState()

  const [sitekey, setSitekey] = useState([])



  const siteKey = () => {
    axios.post("https://lionsapi.emedha.in/contact/site-key").then((res) => {
      setSitekey(res.data)
    })
  }

  useEffect(() => {
    siteKey()
  }, [])

  const loginSuccessfullAlert = () => {
    Swal.fire({
      title: "Successfull !",
      text: "You are login successfullly",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        return
      }
    })
  }

  const adminSuccessfullAlert = () => {
    Swal.fire({
      title: "Successfull !",
      text: "Admin login successfullly",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        return
      }
    })
  }

  const adminFaildAlert = () => {
    Swal.fire({
      title: "Error !",
      text: "User login or password is invalid !",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        return
      }
    })
  }

  const memberData = (email) => {
    axios.post('https://lionsapi.emedha.in/profile', {
      email
    }).then((res) => {
      sessionStorage.setItem('guestLogin', res.data[0].email)
      sessionStorage.setItem('customerName', res.data[0].name)
      sessionStorage.setItem('customerMobile', res.data[0].mobile)
      sessionStorage.setItem('userEmail', email)
      loginSuccessfullAlert()
      setTimeout(() => navigate(`/user-dashboard/${email}`), 700)
      sessionStorage.setItem('userEmail', email)
    })
  }



  const userLoginData = (e) => {
    axios
      .post('https://lionsapi.emedha.in/login', {
        email,
        password,
      }).then((response) => {
        if (response.data.status === 200) {
          memberData(email)
        } else {
          if (response.data.status === 203) {
            adminSuccessfullAlert()
            setTimeout(() => navigate(`/admin-dashboard`), 700)
            sessionStorage.setItem('admin', "jsekjhfri7y45ysjdhfkjhbsdeuoiy4;kfdmslkf")
          } else {
            adminFaildAlert()
          }
        }
      })
  }


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 270 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div style={{ backgroundColor: 'rgb(53, 53, 111)', paddingTop: 10, paddingBottom: 15, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', paddingRight: 29, borderRadius: '0.0003rem' }} className="card"  >
        <img src={logo2} style={{ width: '54px', marginLeft: 14, marginTop: 10 }} />
        <Typography style={{ marginTop: 7 }} >Welcome, <br /> Lions International</Typography>
      </div>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/')} >
            <FaHome style={{ marginRight: 10, color: 'rgb(53, 53, 111)' }} />
            <ListItemText style={{ color: 'rgb(53, 53, 111)' }} primary='Home' />
          </ListItemButton>
        </ListItem>
      </List>

      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/products')}>
            <FaShoppingCart style={{ marginRight: 10, color: 'rgb(53, 53, 111)' }} />
            <ListItemText style={{ color: 'rgb(53, 53, 111)' }} primary='Products' />
          </ListItemButton>
        </ListItem>
      </List>
      <div style={{ position: 'absolute', bottom: 0, width: '100%', borderTop: '1px solid rgb(53, 53, 111)', paddingTop: 10, paddingBottom: 23 }} >
        <Typography style={{ marginBottom: 0, color: 'rgb(53, 53, 111)', fontSize: 11.3, marginTop: 13, marginLeft: 4 }}>@2023 Emedha technologies all right registered.</Typography>
      </div>
    </Box>
  );


  return (
    <div className='card login-main-container' style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, borderRadius: '0.1rem', border: 'none' }}  >
      <div className='navbar3 card' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 5, paddingRight: 5, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', backgroundColor: 'rgb(53, 53, 111)' }} >
        <Typography style={{ fontSize: 13, color: 'white' }} ><EmailIcon style={{ marginTop: '-0.16rem' }} />  lbn320c@gmail.com</Typography>

        <Typography className="d-none d-sm-flex" style={{ fontSize: 17, color: 'white', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>

        <a href="https://api.whatsapp.com/send?phone=+918187094345&text= Hi District 320c" target="_blank">
          <button style={{ border: 'none', background: 'rgb(20, 170, 20)', height: '1.8rem', width: '6.8rem', borderRadius: '0.2rem', color: 'white' }}><FaWhatsapp style={{ fontSize: '1rem', marginTop: '-0.12rem' }} /> Whatsapp</button>
        </a>
      </div>
      <div className='navbar3 card card d-none d-sm-flex' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
        <img src={logo2} style={{ width: '56px' }} />
        <div className="card d-none d-sm-flex" style={{ width: '70%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} >
          <div className="card" style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '15rem', border: 'none' }}>
            <Typography onClick={() => navigate('/')} style={{ color: 'rgb(53, 53, 111)', cursor: 'pointer' }}>
              Home
            </Typography>
            <Typography onClick={() => navigate('/products')} style={{ cursor: 'pointer', color: 'rgb(53, 53, 111)' }}>
              Products
            </Typography>
            <Typography onClick={() => navigate('/contact-us')} style={{ cursor: 'pointer', color: 'rgb(53, 53, 111)' }}>
              Contact US
            </Typography>
          </div>


          <button onClick={() => navigate('/user-login')} className="getButton1" >
            Login/Signup
          </button>

        </div>
        <img src={logo1} style={{ width: '56px' }} />
      </div>
      <div className='navbar3 card card d-sm-none d-flex' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >

        <React.Fragment>

          <FaBars onClick={toggleDrawer('left', true)} style={{ fontSize: '1.2rem', marginLeft: '0.22rem' }} />
          <SwipeableDrawer
            anchor={'left'}
            open={state["left"]}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
          >
            {list('left')}
          </SwipeableDrawer>
        </React.Fragment>
        <Typography className="text-center" style={{ fontSize: 17, color: 'rgb(53, 53, 111)', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>
        <img src={logo1} style={{ width: '36px', marginRight: '0.22rem' }} />
      </div>
      <div className='navbar3 card card d-sm-none d-flex' style={{ borderRadius: '0.0001rem', backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
        <button onClick={() => navigate('/contact-us')} className="getButton2 w-50" >
          Contact Us
        </button>
        <button onClick={() => navigate('/user-login')} className="getButton1 w-50" >
          Login/Signup
        </button>
      </div>
      <div className='col-11 mx-auto card auth-form-container' style={{ border: 'none', marginTop: 61, borderRadius: '1.4rem', }}>
        <div className='row p-1' >

          <div className='col-11 col-md-7 col-lg-4 col-xl-5 mx-auto pb-2' style={{ marginTop: '-4rem' }} >
            <RegisterPageSlider />
          </div>
          <div className='col-11 col-md-7 col-lg-6 col-xl-5 mx-auto p-1' >

            <Typography style={{ fontSize: 19, fontWeight: 'bold', color: 'rgb(53, 53, 111)', marginTop: 12, marginBottom: 3 }}>Welcome to Lions International !</Typography>
            <Typography style={{ fontSize: 13, color: 'rgb(53, 53, 111)', marginTop: 1, marginBottom: 17 }}>Please sign-in to your account and start the adventure</Typography>
            <label>Email <span style={{ color: 'red' }} >*</span></label>
            <input onChange={(e) => {
              setEmail(e.target.value)
            }} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your email' />


            <label>Password <span style={{ color: 'red' }} >*</span></label>
            <input onChange={(e) => {
              setPassword(e.target.value)
            }} type='password' style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.4rem', width: '100%', marginBottom: 4, outline: 'none' }} placeholder='Enter your password' />

            <label>Login type<span style={{ color: 'red' }} >*</span></label>
            <select onChange={(e) => {
              setLogin(e.target.value)
            }} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.4rem', width: '100%', marginBottom: 4, outline: 'none' }} placeholder='Enter your password' >
              <option disabled selected value="user">Select</option>
              <option value="user">User Login</option>
              <option value="admin">Admin Login</option>
            </select>
            <Link to='/password-reset' style={{ textDecoration: 'none' }} ><Typography style={{ marginBottom: 50, marginBottom: 39, color: 'rgb(44, 53, 131)' }}>Forgot password ?</Typography></Link>

            {
              sitekey.map((data) => {
                return (
                  <>
                    <ReCAPTCHA
                      sitekey={data.sitekey}
                      onChange={onChange}
                    />,
                  </>
                )
              })
            }
            <br />
            <Button onClick={() => userLoginData()} variant="contained" style={{ marginBottom: 35, width: '100%', backgroundColor: 'rgb(53, 53, 111)' }}>Login</Button>
            <div style={{ marginBottom: 10 }}>
              <Link to='/User-Signup' style={{ textDecoration: 'none' }} ><Typography style={{ textAlign: 'center', color: 'gray', fontSize: 12 }}>New on our platform? <span style={{ color: 'rgb(44, 53, 131)' }} >Create an account</span></Typography></Link>
            </div>
          </div>
        </div>
      </div>
      <div className='card' style={{ justifyContent: 'center', alignItems: 'center', padding: 10, border: 'none', marginTop: 28, backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
        <div className='card' style={{ width: '10rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
          <FaInstagram size={24} color='#fff'></FaInstagram>
          <FaFacebookF size={24} color='#fff'></FaFacebookF>
          <FaYoutubeSquare size={24} color='#fff'></FaYoutubeSquare>
          <FaTwitterSquare size={24} color='#fff'></FaTwitterSquare>
        </div>
        <div style={{ width: '13rem', backgroundColor: 'white', height: '0.14rem', marginTop: 10 }} >

        </div>
        <Typography style={{ marginBottom: 10, color: 'white', fontSize: 14 }}>@2023 Emedha technologies all right registered.</Typography>
      </div>
    </div>
  )
}

export default UserLoginPage