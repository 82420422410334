import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.css'



const MobileHeaderSlider = () => {

    const settings = {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 3000,
        autoplay: true,
    }

    return (

        <center>
            < Slider {...settings}>

                <center>
                    <img className='ArtistPic1' src={require('../../image/headimg1.jpeg')} />
                </center>

                <center>
                    <img className='ArtistPic1' src={require('../../image/caru1.jpg')} />
                </center>

                <center>
                    <img className='ArtistPic1' src={require('../../image/caru3.jpg')} />
                </center>
                <center style={{ backgroundColor: 'white' }}>
                    <img className='ArtistPic1' src={require('../../image/caru2.jpg')} />
                </center>


            </Slider >
        </center>
    )
}

export default MobileHeaderSlider