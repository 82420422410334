import * as React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Box, Button, Grid, Typography } from '@mui/material';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import Swal from "sweetalert2";
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import logo1 from '../../../image/lionlogo.jpeg'
import logo2 from '../../../image/lions_club.png'
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { FaCalendar, FaCalendarAlt, FaDraftingCompass, FaIdBadge, FaIdCard, FaIdCardAlt, FaMobile, FaProductHunt, FaSellcast, FaUser, FaUserAlt, FaUserCircle, FaWeebly } from 'react-icons/fa';
import Settings from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'Auto',
  color: theme.palette.text.secondary,
}));


export default function AdminPage() {

  const [profileDatam, setProfileData] = useState([])
  const [email, setEmail] = useState()
  const [name, setName] = useState()

  const profile = (email, name) => {
    axios.post('https://lionsapi.emedha.in/profile', {
      email
    }).then((res) => {
      setProfileData(res.data)
      productShow(email)
      setEmail(email)
      setName(name)
    })
  }

  const [productData, setProductData] = useState([])

  const productShow = (req_email_id) => {
    axios.post('https://lionsapi.emedha.in/product/show', {
      req_email_id
    }).then((res) => {
      setProductData(res.data)
      handleClickOpen2()
    })
  }
  const navigate = useNavigate()


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(9);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [member, setUserAdmin] = useState([])

  const approvelConfirmAlert = () => {

    handleClose2()

    Swal.fire({

      title: "Confirmation",
      text: "Are you sure to approve this user",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.value) {
        userDataApprove()
      }
    })
  }

  const approvelSuccess = () => {

    Swal.fire({
      title: "Successfull",
      text: "User successfully approved by admin",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        return
      }
    })

  }

  const userDataAdmin = () => {
    axios.post('https://lionsapi.emedha.in/admin-dashboard').then((res) => {
      setUserAdmin(res.data)
    })
  }

  const userDataApprove = () => {
    setIsApprovePro(true)
    axios.post('https://lionsapi.emedha.in/admin-dashboard/approve', {
      email,
      name
    }).then((res) => {
      approvelSuccess()
      userDataAdmin()
      setIsApprovePro(false)
    })
  }

  React.useEffect(() => {
    userDataAdmin()
  }, [])


  const searchUser = (searchText) => {
    axios.post('https://lionsapi.emedha.in/admin-dashboard/user-search', {
      searchText
    }).then((res) => {
      setUserAdmin(res.data)
    })
  }

  const [open2, setOpen2] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  React.useEffect(() => {
    if (!sessionStorage.getItem('admin')) {
      navigate('/')
    }
  }, [])

  const [isApprovePro, setIsApprovePro] = useState(false)

  return (
    <div>
      <div className='navbar3 card' style={{ borderRadius: '0.0001rem', paddingTop: 1, backgroundColor: 'white', paddingBottom: 1, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', backgroundColor: 'rgb(53, 53, 111)' }} >
        <Typography style={{ fontSize: '1.3rem', color: 'white', fontWeight: '700' }} >Welcome Admin Dashboard</Typography>
        <React.Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 42, height: 42 }}><FaUser size={26} /></Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar />My Profile
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon onClick={() => {
                sessionStorage.removeItem('email')
                navigate('/')
              }} >
                <Logout fontSize="small" />
              </ListItemIcon>
              <Typography onClick={() => {
                sessionStorage.removeItem('email')
                navigate('/')
              }} style={{ color: 'red' }} > Logout</Typography>
            </MenuItem>
          </Menu>
        </React.Fragment>
      </div>
      <div className='navbar3 card cont' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
        <img src={logo2} style={{ width: '56px' }} />
        <div>
          <center>
            <Typography style={{ fontSize: 21, color: 'rgb(53, 53, 111)', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>
          </center>
        </div>
        <img src={logo1} style={{ width: '56px' }} />
      </div>
      <br />
      {
        !isApprovePro && (
          <div className='p-2' >
            <div className='p-2 profileContainer' style={{ borderRadius: '0.2rem' }} >
              <input
                style={{ backgroundColor: 'white', height: '2.7rem', borderRadius: '0.3rem', width: '19rem', border: '0.9px solid lightgray' }}
                placeholder='Search users..'
                onChange={(e) => searchUser(e.target.value)}
              />
              <br />
              <br />
              <Paper elevation={0}>
                <Table>
                  <TableHead sx={{ backgroundColor: 'lightgray', borderRadius: '0.2rem' }}>
                    <TableRow sx={{ backgroundColor: 'lightgray', borderRadius: '0.2rem' }}>
                      <TableCell
                        align={"center"}
                        style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
                      >
                        User
                      </TableCell>
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
                      >
                        Email
                      </TableCell>
                      <TableCell

                        align={"start"}
                        style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                      >
                        Mobile
                      </TableCell>
                      <TableCell

                        align={"start"}
                        style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                      >
                        Membership Number
                      </TableCell>
                      <TableCell

                        align={"start"}
                        style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                      >
                        Status
                      </TableCell>
                      <TableCell

                        align={"start"}
                        style={{ top: 57, minWidth: 23, fontWeight: 'bold', fontSize: 12 }}
                      >
                        Action
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {member
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {

                        let memNo = ""
                        let color = ""
                        let color1 = ""
                        let status = ""

                        if (row.membershipNo === "") {
                          memNo = "Non-Lion Member"
                          color = "red"
                        } else {
                          memNo = row.membershipNo
                          color = "green"
                        }

                        if (row.isApprove === 0) {
                          color1 = "orange"
                          status = "Pendding"
                        } else {
                          color1 = "green"
                          status = "Approved"
                        }

                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            <TableCell align={"center"}
                              style={{ top: 57, minWidth: 21, fontSize: 12 }}><FaUserCircle style={{ fontSize: 29, color: color1 }} /></TableCell>
                            <TableCell align={"start"}
                              style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.name}</TableCell>
                            <TableCell align={"start"}
                              style={{ top: 57, minWidth: 87, fontSize: 12 }}>{row.email}</TableCell>
                            <TableCell align={"start"}
                              style={{ top: 57, minWidth: 87, fontSize: 12 }}>{row.mobile}</TableCell>
                            <TableCell align={"start"}
                              style={{ top: 57, minWidth: 87, fontSize: 12 }}>
                              <button disabled={true} style={{ border: `1.3px solid ${color}`, borderRadius: '0.9rem', backgroundColor: "white", color: color }} >{memNo}</button>
                            </TableCell>

                            <TableCell align={"start"}
                              style={{ top: 57, minWidth: 87, fontSize: 12 }}>
                              <button disabled={true} style={{ border: `1.3px solid ${color1}`, borderRadius: '0.9rem', backgroundColor: color1, color: 'white' }} >{status}</button>
                            </TableCell>

                            <TableCell align={"start"}
                              style={{ top: 57, minWidth: 23, fontSize: 12, flexDirection: 'row', alignItems: 'center' }}>
                              <button disabled={row.isApprove === 0 ? false : true} onClick={() => profile(row.email, row.name)} className='shadow' style={{ backgroundColor: row.isApprove === 0 ? 'rgb(53, 53, 111)' : 'lightgray', color: 'white', borderRadius: '0.9rem', border: row.isApprove === 0 ? '1.3px solid rgb(53, 53, 111)' : '1.3px solid lightgray', marginRight: 2 }} >Aprove</button>
                              {
                                row.isApprove !== 0 && (
                                  <button onClick={() => profile(row.email)} className='shadow' style={{ backgroundColor: 'green', color: 'white', borderRadius: '0.9rem', border: '1.3px solid green' }} >View</button>
                                )
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <div>

                  <Dialog
                    fullScreen={fullScreen}
                    open={open2}
                    maxWidth='xl'
                    onClose={handleClose2}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogTitle id="responsive-dialog-title">
                      {"Approve User"}
                    </DialogTitle>
                    <DialogContent style={{ width: '82rem', height: '40rem' }} >
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Item>
                            {
                              profileDatam.map((data) => {

                                let isLionsMem = ""

                                if (data.membershipNo === "") {
                                  isLionsMem = false
                                } else {
                                  isLionsMem = true
                                }

                                return (
                                  <div>
                                    <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none' }} >
                                      <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Basic Information</Typography>
                                    </div>
                                    <div style={{ borderBottom: '1px solid rgb(53, 53, 111)', marginBottom: 10 }} >
                                      <img src='https://th.bing.com/th/id/OIP.audMX4ZGbvT2_GJTx2c4GgHaHw?w=180&h=188&c=7&r=0&o=5&dpr=2.5&pid=1.7' style={{ width: '100px' }} />
                                    </div>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaIdBadge style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Membership No:  {isLionsMem ? `${data.membershipNo}` : "Non-Lion Member"}</Typography>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaUserAlt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Name: {data.name}</Typography>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaMobile style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Mobile: {data.mobile}</Typography>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><EmailIcon style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Email: {data.email}</Typography>
                                  </div>
                                )
                              })
                            }
                          </Item>
                        </Grid>
                        <Grid item xs={4}>
                          <Item>
                            {
                              profileDatam.map((data) => {
                                return (
                                  <div>
                                    <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none' }} >
                                      <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Lion Information</Typography>
                                    </div>
                                    <div style={{ borderBottom: '1px solid rgb(53, 53, 111)', marginBottom: 10 }} >
                                      <img src='https://th.bing.com/th/id/OIP._LZ97WsRvlwvwWTh7P4fUAAAAA?w=170&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' style={{ width: '100px' }} />
                                    </div>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaCalendar style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Joined Year: {data.joined_year}</Typography>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaIdCardAlt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Position: {data.position}</Typography>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaCalendarAlt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Position Year: {data.year}</Typography>
                                  </div>
                                )
                              })
                            }
                          </Item>
                        </Grid>
                        <Grid item xs={4}>
                          <Item>
                            {
                              profileDatam.map((data) => {
                                return (
                                  <div>
                                    <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none' }} >
                                      <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Business Information</Typography>
                                    </div>
                                    <div style={{ borderBottom: '1px solid rgb(53, 53, 111)', marginBottom: 10 }} >
                                      <img src={data.logo} style={{ width: '100px' }} />
                                    </div>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaDraftingCompass style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Name Of Company: {data.name_of_company}</Typography>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaIdCard style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Designation: {data.designation}</Typography>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaWeebly style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Website: {data.website}</Typography>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><EmailIcon style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Email Id: {data.official_email_id}</Typography>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaProductHunt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Product: {data.product}</Typography>
                                    <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaSellcast style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Business Vertical: {data.businessVerticalData}</Typography>
                                  </div>
                                )
                              })
                            }
                          </Item>
                        </Grid>
                        <Grid item xs={12}>
                          <Item>
                            <div className='row gy-4 p-3' >
                              <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none' }} >
                                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Products</Typography>
                              </div>
                              {
                                productData.map((data) => {
                                  return (
                                    <div className='col-12 col-xl-2 col-md-6' >

                                      <div style={{ cursor: 'pointer', height: '14rem', border: '1px solid lightgray', borderRadius: '0.2rem', overflow: 'hidden' }} >
                                        <img src={data.productPhoto} style={{ width: '100%' }} />
                                      </div>
                                    </div>
                                  )
                                })
                              }
                              {
                                productData.length === 0 && (
                                  <center style={{ marginTop: 60, marginBottom: 60 }} >
                                    <img src={logo2} style={{ width: '35px' }} />
                                    <Typography style={{ width: '60%', textAlign: 'center' }} >If You have product data add and improve your profile</Typography>
                                  </center>
                                )
                              }
                            </div>
                          </Item>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    {
                      profileDatam.map((data) => {
                        return (
                          <DialogActions>
                            <Button style={{ background: 'red', color: 'white' }} autoFocus onClick={handleClose2}>
                              Cancel
                            </Button>
                            {
                              data.isApprove !== 0 && (
                                <Button style={{ background: 'lightgray', color: 'white' }} autoFocus>
                                  Aproved
                                </Button>
                              )
                            }
                            {
                              data.isApprove === 0 && (
                                <Button style={{ background: 'green', color: 'white' }} onClick={() => {
                                  approvelConfirmAlert()
                                }} autoFocus>
                                  Aprove
                                </Button>
                              )
                            }
                          </DialogActions>
                        )
                      })
                    }
                  </Dialog>
                </div>
                <br />
                <TablePagination
                  rowsPerPageOptions={[5, 20, 50, 100]}
                  component="div"
                  count={member.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        )
      }
  
      {
        isApprovePro && (
          <div className='card' style={{ borderRadius: '0.001rem', backgroundColor: 'black', position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, zIndex:12, opacity:'0.4' }}  ></div>
        )
      }
           {
        isApprovePro && (
          <div className='card' style={{ borderRadius: '0.001rem', position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, justifyContent:'center', alignItems:'center' }}  >
            <img src={logo2} width='40px' style={{zIndex:13}} ></img>
            <Typography style={{zIndex:13}} >Please Wait... !!</Typography>
          </div>
        )
      }
    </div>
  );
}