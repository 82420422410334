import { Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const PasswordTokenValidation = () => {

    const { token } = useParams()
    const { email } = useParams()
    const navigate = useNavigate()

    const tokenExpireAlert = () => {
        Swal.fire({

            title: "Expire",
            text: "Your create account token is has been expired please once again signup",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok"
        }).then((result) => {
            if (result.value) {
                return
            }
        })
    }

    const [isTokenValProcess, setIsTokenValProcess] = useState(false)

    useEffect(() => {
        setIsTokenValProcess(true)
        axios.post('https://lionsapi.emedha.in/password/token-validation', {
            token
        }).then((res) => {
            if (res.data.status === 200) {
                navigate(`/change-password/${token}/${email}`)
                setIsTokenValProcess(false)
            } else {
                tokenExpireAlert()
            }
        })
    })

    return (
        <>
            <div>
                {
                    isTokenValProcess && (
                        <div className="card" style={{ height: '100vh', width: '100%', justifyContent: 'center', alignItems: 'center' }} >
                            <img src="https://th.bing.com/th/id/OIP.Po5ZWzBK65O7vQsGsrS7nAHaG1?w=206&h=191&c=7&r=0&o=5&dpr=2.5&pid=1.7" style={{ width: '58px' }} />
                            <Typography>Please Wait..</Typography>
                        </div>
                    )
                }
            </div>
        </>
    )


}

export default PasswordTokenValidation