import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { FaBong, FaCalendar, FaCalendarAlt, FaDailymotion, FaDraftingCompass, FaEdit, FaFacebook, FaFacebookF, FaIdBadge, FaIdCard, FaIdCardAlt, FaInbox, FaInstagram, FaMobile, FaPlusCircle, FaPoundSign, FaProductHunt, FaRubleSign, FaRupeeSign, FaSellcast, FaTrash, FaTrashAlt, FaTwitter, FaTwitterSquare, FaUser, FaUserAlt, FaWeebly, FaYammer, FaYandex, FaYarn, FaYoutube, FaYoutubeSquare } from 'react-icons/fa';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { Form } from 'react-bootstrap'
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Swal from 'sweetalert2';
import logo1 from '../../image/lionlogo.jpeg'
import logo2 from '../../image/lions_club.png'
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'Auto',
  color: theme.palette.text.secondary,
}));


export default function FullWidthGrid() {



  const { req_email_id } = useParams()


  const [anchorEl, setAnchorEl] = useState(null);
  const [profileData, setProfileData] = useState([])
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const profile = (email = req_email_id) => {
    axios.post('https://lionsapi.emedha.in/profile', {
      email
    }).then((res) => {
      setProfileData(res.data)
    })
  }

  const [lionsData, setLionsData] = useState([])
  const [isLions, setIsLions] = useState(true)
  const [isBusiness, setIsBusiness] = useState(true)
  const [isLionsMem, setIsLionsMem] = useState(true)

  const lions_data_show = (email = req_email_id) => {
    axios.post('https://lionsapi.emedha.in/profile/lions_data/show', {
      email
    }).then((res) => {
      setLionsData(res.data)
      if (res.data[0].joined_year === null) {
        setIsLions(false)
      }
      if (res.data[0].name_of_company === null) {
        setIsBusiness(false)
      }

      if (res.data[0].membershipNo === "") {
        setIsLionsMem(false)
      } else {
        setIsLionsMem(true)
      }
    })
  }

  useEffect(() => {
    lions_data_show()
  }, [])

  useEffect(() => {
    profile()
  }, [])

  const [open1, setOpen1] = useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
    setProfileSearchData([])
  };




  const [open2, setOpen2] = useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };




  const [open3, setOpen3] = useState(false);

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const [open4, setOpen4] = useState(false);

  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
    setIsEdit(false)
  };


  const [pName, setPName] = useState("")
  const [pPrice, setPPrice] = useState("")
  const [pDesc, setPDesc] = useState("")
  const [pPhoto, setPPhoto] = useState("")
  const [offerValidity, setOfferValidity] = useState("00/00/00")
  const [offer, setOffer] = useState(0)
  const [productError, setProductError] = useState(false)

  const productValidation = () => {
    if (pName === "" || pPrice === "" || pDesc === "" || pPhoto === "") {
      setProductError(true)
    } else {
      addProduct()
    }
  }

  const productAddAlert = () => {
    Swal.fire({

      title: "Successfull",
      text: "Your product is successfully added",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        return
      }
    })
  }

  const lionsDataAlert = () => {
    Swal.fire({

      title: "Successfull",
      text: "Your lion data is successfully added",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        return
      }
    })
  }

  const busineDatAddAlert = () => {
    Swal.fire({

      title: "Successfull",
      text: "Your business data is successfully added",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        return
      }
    })
  }

  const lionsDataAlertfailed = () => {
    Swal.fire({

      title: "Warning",
      text: "Any field can't be empty",
      confirmButtonColor: "red",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        return
      }
    })
  }

  const addProduct = (e) => {

    const formData = new FormData();

    formData.append('pName', pName)
    formData.append('pPrice', pPrice)
    formData.append('pAbout', pDesc);
    formData.append('photo', pPhoto);
    formData.append('validity', offerValidity);
    formData.append('offer', offer);
    formData.append('userEmail', req_email_id);


    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    axios.post("https://lionsapi.emedha.in/product/add", formData, config)
      .then((response) => {
        setOpen4(false)
        productShow()
        productAddAlert()
      }).catch((error) => {
      });
  }

  const [productData, setProductData] = useState([])

  const productShow = () => {
    axios.post('https://lionsapi.emedha.in/product/show', {
      req_email_id
    }).then((res) => {
      setProductData(res.data)
    })
  }

  useEffect(() => {
    productShow()
  }, [])

  const navigate = useNavigate()

  useEffect(() => {
    if (!sessionStorage.getItem('userEmail')) {
      navigate('/')
    }
  }, [])

  const [joinedYear_l, setJoinedYear] = useState("")

  const [initialization, setInitialization] = useState([{
    positionData: "",
    yearData: "",

  }
  ])


  const handelInputField = (e, index) => {
    const values = [...initialization]
    values[index][e.target.name] = e.target.value

    setInitialization(values)
  }



  const dynamicInput = () => {
    const _initialization = [...initialization]
    _initialization.push({
      positionData: "",
      yearData: "",

    })
    setInitialization(_initialization)
  }

  const lions_data = async (e) => {

    e.preventDefault();

    if (joinedYear_l === "") {
      handleClose2()
      lionsDataAlertfailed()
      return
    }



    await
      axios
        .post('https://lionsapi.emedha.in/profile/lions_data', {

          joinedYear_l,
          initialization,
          req_email_id

        }).then((resp) => {
          handleClose2()
          lions_data_show()
          lionsDataAlert()
        })
  }

  const [name_of_company, set_name_of_company] = useState("")
  const [designation, setDesignation] = useState("")
  const [website, setWebsite] = useState("")
  const [businessData_b, setBussinessData] = useState("")
  const [official_email_id, setOfficialEmailId] = useState("")
  const [logo_b, setLogo] = useState("")
  const [productServices, setProductServices] = useState("")
  const [businessVer, setBusinessVerticle] = useState("")

  const [businessVerError, setBusinessVerError] = useState(false)

  const businessVerrticaleValidation = () => {

    if (name_of_company === "" || designation === "" || businessData_b === "" || official_email_id === "" || logo_b === "" || productServices === "" || businessVer === "") {
      setBusinessVerError(true)
    } else {
      business()
    }
  }


  const business = (e) => {


    const formData = new FormData();

    formData.append('email', req_email_id)
    formData.append('companyName', name_of_company)
    formData.append('designation', designation)
    formData.append('website', website)
    formData.append('business_email', official_email_id)
    formData.append('business', businessData_b)
    formData.append('product', productServices)
    formData.append('businessVer', businessVer)
    formData.append('logo', logo_b)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    axios.post("https://lionsapi.emedha.in/profile/business", formData, config)
      .then((response) => {
        handleClose3()
        busineDatAddAlert()
        profile()
      }).catch((error) => {
      });
  }

  const [profileSearchData, setProfileSearchData] = useState([])

  const searchProfile = (searchText) => {

    if (searchText === "") {
      setProfileSearchData([])
      return
    }

    axios.post('https://lionsapi.emedha.in/profile/profile-search', {
      searchText
    }).then((res) => {
      setProfileSearchData(res.data)
    })
  }


  const disableDate = () => {
    var today, dd, mm, yyyy;
    today = new Date();
    dd = today.getDate() + 1;
    mm = today.getMonth() + 1
    yyyy = today.getFullYear()
    return yyyy + "-" + mm + "-" + dd;
  }

  const [id, setId] = useState()
  const [isEdit, setIsEdit] = useState(false)


  const editProduct = (id, productName, productPhoto, productDesc, productPrice, offer, validity) => {
    setId(id)
    setPName(productName)
    setPPhoto(productPhoto)
    setPDesc(productDesc)
    setPPrice(productPrice)
    setOffer(offer)
    setOfferValidity(validity)
    setOpen4(true)
    setIsEdit(true)
  }


  const editCA = () => {
    setOpen4(false)
    Swal.fire({
      title: "Warning",
      text: "Are you sure to edit product details",
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.value) {
        editProductData()
      } else {
        setPName("")
        setPPhoto("")
        setPDesc("")
        setOffer("")
        setOfferValidity("")
        return
      }
    })
  }

  const deleteCA = (id) => {
    setOpen4(false)
    Swal.fire({
      title: "Warning",
      text: "Are you sure to delete product details",
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.value) {
        productDelete(id)
      } else {
        return
      }
    })
  }

  const productDelete = (id) => {
    axios.post('https://lionsapi.emedha.in/product/delete', {
      id
    }).then((res) => {
      if (res.data.statusCode === 200) {
        productShow()
        Swal.fire({
          title: "Successfull !",
          text: "Product details deleted Successfully",
          confirmButtonColor: "green",
          cancelButtonColor: "#d33",
          showCancelButton: false,
          confirmButtonText: "Ok"
        }).then((result) => {
          if (result.value) {
            return
          }
        })
      }
    })
  }

  const editProductData = (e) => {

    const formData = new FormData();

    formData.append('pName', pName)
    formData.append('pPrice', pPrice)
    formData.append('pAbout', pDesc);
    formData.append('photo', pPhoto);
    formData.append('validity', offerValidity);
    formData.append('offer', offer);
    formData.append('id', id);

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    axios.post("https://lionsapi.emedha.in/product/edit", formData, config)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setOpen4(false)
          setPName("")
          setPPhoto("")
          setPDesc("")
          setOffer("")
          setOfferValidity("")
          setPPrice("")
          productShow()
          Swal.fire({
            title: "Successfull !",
            text: "Product details edited Successfully",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok"
          }).then((result) => {
            if (result.value) {
              return
            }
          })
        }
      })
  }


  return (
    <Box sx={{ flexGrow: 1 }}>

      <div className='navbar3 card' style={{ borderRadius: '0.0001rem', paddingTop: 1, backgroundColor: 'white', paddingBottom: 1, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', backgroundColor: 'rgb(53, 53, 111)' }} >
        <input onClick={() => handleClickOpen1()} style={{ paddingLeft: 10, height: '2.1rem', border: '0.8px solid lightgray', borderRadius: '1rem', width: '50%', outline: 'none', cursor: 'pointer' }} readOnly placeholder='Search profile' />
        <React.Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 42, height: 42 }}><FaUser size={26} /></Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar />My Profile
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon onClick={() => {
                sessionStorage.removeItem('email')
                navigate('/')
              }} >
                <Logout fontSize="small" />
              </ListItemIcon>
              <Typography onClick={() => {
                sessionStorage.removeItem('email')
                navigate('/')
              }} style={{ color: 'red' }} > Logout</Typography>
            </MenuItem>
          </Menu>
        </React.Fragment>
      </div>
      <div className='navbar3 card cont' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
        <img src={logo2} style={{ width: '56px' }} />
        <div>
          <center>
            <Typography style={{ fontSize: 21, color: 'rgb(53, 53, 111)', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>
          </center>
        </div>
        <img src={logo1} style={{ width: '56px' }} />
      </div>

      <Grid container spacing={2} sx={{ p: 2 }} >
        <Grid item sm={12} md={4} sx={{ width: '100%' }} >
          <div className='profileContainer' >
            <Item style={{ height: '70VH', overflow: 'auto' }} elevation={0} >
              {
                profileData.map((data) => {

                  return (
                    <div>
                      <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none' }} >
                        <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Basic Information</Typography>
                      </div>
                      <div style={{ borderBottom: '1px solid rgb(53, 53, 111)', marginBottom: 10 }} >
                        <img src='https://th.bing.com/th/id/OIP.audMX4ZGbvT2_GJTx2c4GgHaHw?w=180&h=188&c=7&r=0&o=5&dpr=2.5&pid=1.7' style={{ width: '100px' }} />
                      </div>
                      <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaIdBadge style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Membership No:  {isLionsMem ? `${data.membershipNo}` : "Non-Lion Member"}</Typography>
                      <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaUserAlt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Name: {data.name}</Typography>
                      <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaMobile style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Mobile: {data.mobile}</Typography>
                      <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><EmailIcon style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Email: {data.email}</Typography>
                      <div className='card' style={{ border: 'none', height: '5rem', width: '100%', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.01rem', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', paddingLeft: 27, paddingRight: 27, marginTop: 32 }} >
                        <div className='card' style={{ height: 38, width: 38, borderRadius: 19, justifyContent: 'center', alignItems: 'center', border: '2px solid white', backgroundColor: 'rgb(53, 53, 111)' }} >
                          <FaInstagram style={{ color: 'white', fontSize: 22 }} ></FaInstagram>
                        </div>
                        <div className='card' style={{ height: 38, width: 38, borderRadius: 19, justifyContent: 'center', alignItems: 'center', border: '2px solid white', backgroundColor: 'rgb(53, 53, 111)' }} >
                          <FaTwitter style={{ color: 'white', fontSize: 22 }} ></FaTwitter>
                        </div>
                        <div className='card' style={{ height: 38, width: 38, borderRadius: 19, justifyContent: 'center', alignItems: 'center', border: '2px solid white', backgroundColor: 'rgb(53, 53, 111)' }} >
                          <FaFacebook style={{ color: 'white', fontSize: 22 }} ></FaFacebook>
                        </div>
                        <div className='card' style={{ height: 38, width: 38, borderRadius: 19, justifyContent: 'center', alignItems: 'center', border: '2px solid white', backgroundColor: 'rgb(53, 53, 111)' }} >
                          <FaYoutube style={{ color: 'white', fontSize: 22 }} ></FaYoutube>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </Item>
          </div>
        </Grid>
        <Grid item sm={12} md={4} sx={{ width: '100%' }}>
          <div className='profileContainer' >
            <Item elevation={0} style={{ height: '70vh' }} >
              <div>
                {
                  lionsData.map((data) => {
                    return (
                      <div>
                        <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none', width: '100%' }} >
                          <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Lions Information</Typography>
                        </div>

                        {
                          isLions && (
                            <div>
                              <div style={{ borderBottom: '1px solid rgb(53, 53, 111)', marginBottom: 10 }} >
                                <img src='https://th.bing.com/th/id/OIP._LZ97WsRvlwvwWTh7P4fUAAAAA?w=170&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' style={{ width: '100px' }} />
                              </div>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaCalendar style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Joined Year: {data.joined_year}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaIdCardAlt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Position: {data.position}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaCalendarAlt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Position Year: {data.year}</Typography>
                            </div>
                          )
                        }
                      </div>
                    )
                  })
                }
              </div>
              {
                (!isLions && isLionsMem) && (
                  <center style={{ marginTop: 120 }} >
                    <img src={logo2} style={{ width: '35px' }} />
                    <Typography style={{ width: '60%', textAlign: 'center', color: 'black' }} >If You are a lions member add lions data to improve your profile</Typography>
                    <Button onClick={() => {
                      handleClickOpen2()
                      setTimeout(() => lions_data_show(), 190)
                    }} style={{ backgroundColor: 'rgb(53, 53, 111)', color: 'white' }} ><FaPlusCircle />  <span style={{ marginLeft: 3 }} >Add</span></Button>
                  </center>
                )
              }

              {
                !isLionsMem && (
                  <center style={{ marginTop: 120 }} >
                    <img src={logo2} style={{ width: '35px' }} />
                    <Typography style={{ width: '60%', textAlign: 'center', color: 'black' }} >Your are not a lions member so you have not add lion data</Typography>
                    <Button disabled={true} variant='contained'  ><FaPlusCircle />  <span style={{ marginLeft: 3 }} >Add</span></Button>
                  </center>
                )
              }
            </Item>
          </div>
        </Grid>
        <Grid item sm={12} md={4} sx={{ width: '100%' }}>
          <div className='profileContainer' >
            <Item elevation={0} style={{ height: '70vh', overflow: 'auto' }} >
              <div>
                {
                  lionsData.map((data) => {
                    return (
                      <div>
                        <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none' }} >
                          <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Business Information</Typography>
                        </div>

                        {
                          isBusiness && (
                            <div>
                              <div style={{ borderBottom: '1px solid rgb(53, 53, 111)', marginBottom: 10 }} >
                                <img src={data.logo} style={{ width: '100px' }} />
                              </div>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaDraftingCompass style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Name Of Company: {data.name_of_company}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaIdCard style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Designation: {data.designation}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaWeebly style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Website: {data.website}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><EmailIcon style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Email Id: {data.official_email_id}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaProductHunt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Product: {data.product}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaProductHunt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Business/Profession: {data.businessProfession}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaSellcast style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Business Vertical: {data.businessVerticalData}</Typography>
                            </div>
                          )
                        }
                      </div>
                    )
                  })
                }
              </div>


              {
                !isBusiness && (
                  <center style={{ marginTop: 120 }} >
                    <img src={logo2} style={{ width: '35px' }} />
                    <Typography style={{ width: '60%', textAlign: 'center', color: 'black' }} >If You have a business add your business data to improve your profile</Typography>
                    <Button onClick={() => {
                      handleClickOpen3()
                      setTimeout(() => lions_data_show(), 500)
                    }} style={{ backgroundColor: 'rgb(53, 53, 111)', color: 'white' }} ><FaPlusCircle />  <span style={{ marginLeft: 3 }} >Add</span></Button>
                  </center>
                )
              }
            </Item>
          </div>
        </Grid>
        <Grid item md={12}>
          <div className='profileContainer' >
            <Item elevation={0} style={{ height: productData.length === 0 ? '70vh' : "", paddingBottom: 10 }} >
              <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none' }} >
                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Add Your Products</Typography>
              </div>
              <div className='card' style={{ border: 'none', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', padding: 10 }} >
                <Typography style={{ fontSize: '1.9rem', fontWeight: 'bold', color: 'rgb(53, 53, 111)' }} >
                  All Products
                  <div style={{ borderTop: '4px solid rgb(53, 53, 111)', width: '2.6rem', marginTop: '-0.2rem' }} ></div>
                </Typography>
                {
                  productData.length !== 0 && (
                    <center style={{ marginTop: 10, marginBottom: 10 }} >
                      <Button onClick={() => handleClickOpen4()} style={{ backgroundColor: 'rgb(53, 53, 111)', color: 'white' }} ><FaPlusCircle />  <span style={{ marginLeft: 3 }} >Add</span></Button>
                    </center>
                  )
                }
              </div>
              <div className='container-fluid pb-2' >
                <div className="row gy-3" >
                  {
                    productData.map((data) => {
                      return (
                        <div className="col-12 col-md-10 col-lg-3">
                          <article class="card1 card " style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ height: '24rem', overflow: 'hidden' }} >
                              <img
                                class="card1__background"
                                src={data.productPhoto}
                                alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                              />
                            </div>
                            <div class="card1__content | flow" style={{ width: '21rem', height: '24rem' }} >
                              <div class="card1__content--container | flow">
                                <h2 class="card1__title" style={{ fontSize: '1.5rem' }} >{data.productName}</h2>
                                <p style={{ fontSize: 13 }}>Price: <FaRupeeSign />{data.productPrice - data.offer / 100 * data.productPrice} <del style={{ fontSize: 12, position: 'relative', top: -5 }}>{data.productPrice}</del> <span style={{ fontSize: 15, fontWeight: 'bold', color: 'blue' }} > Off {data.offer} %</span> </p>
                                <p>Offer Validity: {data.validity}</p>
                                <p style={{ color: 'white', fontWeight: 'bold' }} >  </p>
                                <p class="card1__description">
                                  {data.productDesc}
                                </p>
                              </div>
                              <button onClick={() => editProduct(data.id, data.productName, data.productPhoto, data.productDesc, data.productPrice, data.offer, data.validity)} class="card1__button" style={{ borderRadius: '0.2rem' }}><FaEdit /> Edit</button>
                              <button onClick={()=> deleteCA(data.id)} class="card3__button" style={{ borderRadius: '0.2rem' }}><FaTrashAlt /> Delete</button>
                            </div>
                          </article>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              {
                productData.length === 0 && (
                  <center style={{ marginTop: 120 }} >
                    <img src={logo2} style={{ width: '35px' }} />
                    <Typography style={{ width: '60%', textAlign: 'center' }} >If You have product data add and improve your profile</Typography>
                    <Button onClick={() => handleClickOpen4()} style={{ backgroundColor: 'rgb(53, 53, 111)', color: 'white' }} ><FaPlusCircle />  <span style={{ marginLeft: 3 }} >Add</span></Button>
                  </center>
                )
              }
            </Item>
          </div>
        </Grid>
      </Grid>
      <div>
        <Dialog
          open={open1}
          onClose={handleClose1}
          aria-labelledby="alert-dialog-title"
          maxWidth='lg'
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ width: '32rem', height: '35rem' }} >
            <Typography style={{ fontSize: '1.2rem', color: 'rgb(53, 53, 111)' }} >Search Profile</Typography>
            <br />
            <TextField onChange={(e) => searchProfile(e.target.value)} id="standard-basic" label="Search" variant="standard" autoFocus style={{ width: '100%' }} />
            <br />
            <br />
            {
              profileSearchData.length === 0 && (
                <center style={{ marginTop: 43 }} >
                  <img src={logo2} style={{ width: '35px' }} />
                  <Typography style={{ color: 'black' }}>Search profile to explore data</Typography>
                </center>
              )
            }
            {
              profileSearchData.map((data) => {
                return (
                  <Box>
                    <Box onClick={() => navigate(`/user-profile/${data.email}`)} sx={{ width: '100%', padding: '0.4rem', backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', marginBottom: 1.2, cursor: 'pointer' }} >
                      <Typography style={{ color: 'rgb(53, 53, 111)' }} >{data.name}, {data.name_of_company}</Typography>
                    </Box>
                  </Box>
                )
              })
            }
          </DialogContent>
          <DialogActions>
            <Button style={{ backgroundColor: 'rgb(53, 53, 111)' }} variant='contained' onClick={handleClose1} autoFocus>
              Cancel
            </Button>

          </DialogActions>
        </Dialog>
      </div>
      <div>

        <Dialog
          open={open2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          maxWidth='lg'
          aria-describedby="alert-dialog-description"
        >

          <DialogContent className='card' style={{ width: '39rem', justifyContent: 'center', alignItems: 'center', border: 'none' }}  >
            <DialogContentText id="alert-dialog-description">
              <form onSubmit={lions_data}>
                <input type='text' placeholder='Joined Year' className='form-control' onChange={(e) => setJoinedYear(e.target.value)} />
                {
                  initialization.map((data, index) => {
                    return (
                      <>
                        <br />
                        <div className='d-flex' key={index}>
                          <select className='form-control' name='positionData' value={data.positionData} onChange={(e) => handelInputField(e, index)}>
                            <option>position</option>
                            <option value='Club President'>Club President</option>
                            <option value='District Governor'>District Governor	</option>
                            <option value='Multiple Council Chairperson'> Multiple Council Chairperson</option>
                            <option value='International President'> International President</option>
                            <option value='Club First Vice President'>  Club First Vice President	</option>
                            <option value='First Vice District Governor'>  First Vice District Governor	</option>
                            <option value='Past Multiple Council Chairperson	'>Past Multiple Council Chairperson	</option>
                            <option value='Past International President' >Past International President</option>
                            <option value='Club Second Vice President'>Club Second Vice President</option>
                            <option value='Second Vice District Governor' >Second Vice District Governor	</option>
                            <option value='Multiple Vice Council Chairperson'>Multiple Vice Council Chairperson	</option>
                            <option value='First Vice International President'>First Vice International President</option>
                            <option value='Cabinet Secretary'>Cabinet Secretary	</option>
                            <option value='Club Secretary'>Club Secretary	</option>
                            <option value=' Council Secretary'>  Council Secretary	</option>
                            <option value='Second Vice International President'>Second Vice International President</option>
                            <option value='Club Treasurer	 '>Club Treasurer	 	</option>
                            <option value=' 	Cabinet Treasurer'> 	Cabinet Treasurer	</option>
                            <option value='Council Treasurer'>Council Treasurer	</option>
                            <option value=' Third Vice International President'> Third Vice International President</option>
                            <option value='Club Membership Chairperson'>Club Membership Chairperson	</option>
                            <option value='District Convention Chairperson'> District Convention Chairperson	</option>
                            <option value='LCIF Multiple District Coordinator	'> LCIF Multiple District Coordinator	</option>
                            <option value='International Director First Year'>International Director First Year</option>
                            <option value=' Club LCIF Coordinator'> Club LCIF Coordinator	</option>
                            <option value='District Diabetes Chairperson'>District Diabetes Chairperson	</option>
                            <option value='Leo/Leo-Lion Council Liaison'>  Leo/Leo-Lion Council Liaison	</option>
                            <option value='International Director Second Year'>International Director Second Year</option>
                            <option value='Club Service Chairperson'>Club Service Chairperson	</option>
                            <option value='District Environment Chairperson'>District Environment Chairperson	</option>
                            <option value='Multiple District Convention Chairperson'>Multiple District Convention Chairperson	</option>
                            <option value='Club Marketing Chairperson'>Club Marketing Chairperson	</option>
                            <option value='District Global Extension Team Coordinator'> District Global Extension Team Coordinator</option>
                            <option value='Multiple District Diabetes Chairperson'>Multiple District Diabetes Chairperson	</option>
                            <option value='Club Director'>Club Director	</option>
                            <option value='District GLT Coordinator'>District GLT Coordinator</option>
                            <option value=' Multiple District Environment Chairperson'>  Multiple District Environment Chairperson	</option>
                            <option value='Club Administrator'>Club Administrator</option>
                            <option value=' District GMT Coordinator'> District GMT Coordinator	</option>
                            <option value='Multiple District GLT Coordinator'>Multiple District GLT Coordinator	</option>
                            <option value='District GMT Family & Women’s Specialist'>District GMT Family & Women’s Specialist	</option>

                            <option value='Multiple District GMT Coordinator'>Multiple District GMT Coordinator	</option>
                            <option value='District GST Coordinator'> District GST Coordinator	</option>
                            <option value='Multiple District GMT Family & Women’s Specialist'>Multiple District GMT Family & Women’s Specialist</option>
                            <option value='District Honorary Committee Chairperson'>District Honorary Committee Chairperson	</option>
                            <option value='Multiple District GST Coordinator'>  Multiple District GST Coordinator	</option>
                            <option value=' District Hunger Chairperson'> District Hunger Chairperson	</option>
                            <option value='Multiple District Hunger Chairperson'>Multiple District Hunger Chairperson	</option>
                            <option value='District Information Technology Chairperson'>District Information Technology Chairperson	</option>
                            <option value='Multiple District Information Technology Chairperson'>Multiple District Information Technology Chairperson</option>
                            <option value=' District Leo Chairperson	'> District Leo Chairperson	</option>
                            <option value='Multiple District Leo Chairperson'>Multiple District Leo Chairperson	</option>
                            <option value='District Lions Quest Chairperson	'> District Lions Quest Chairperson	</option>
                            <option value='Multiple District Lions Quest Chairperson'> Multiple District Lions Quest Chairperson	</option>
                            <option value='District Marketing Chairperson'>  District Marketing Chairperson	</option>
                            <option value='Multiple District Marketing Chairperson'>Multiple District Marketing Chairperson	</option>
                            <option value='District Peace Poster Contest Chairperson'> District Peace Poster Contest Chairperson	</option>
                            <option value='Multiple District Pediatric Cancer Chairperson'>Multiple District Pediatric Cancer Chairperson	</option>
                            <option value='District Pediatric Cancer Chairperson'> District Pediatric Cancer Chairperson	</option>
                            <option value='Multiple District Protocol Chairperson'>Multiple District Protocol Chairperson	</option>
                            <option value='District Public Relations & Lions Information Chairperson'>District Public Relations & Lions Information Chairperson</option>
                            <option value='Multiple District Public Relations & Lions Information Chairperson'>  Multiple District Public Relations & Lions Information Chairperson</option>
                            <option value='District Reading Action Program Chairperson'>District Reading Action Program Chairperson	</option>
                            <option value='Multiple District Reading Action Program Chairperson'> Multiple District Reading Action Program Chairperson</option>
                            <option value='District Vision Chairperson'> District Vision Chairperson	</option>
                            <option value=' Multiple District Vision Chairperson'> Multiple District Vision Chairperson	</option>
                            <option value=' District Youth Camp and Exchange Chairperson'> District Youth Camp and Exchange Chairperson	</option>
                            <option value='Multiple District Youth Camp and Exchange Chairperson'>Multiple District Youth Camp and Exchange Chairperson</option>
                            <option value='LCIF District Coordinator'>   LCIF District Coordinator		</option>
                            <option value='Leo/Leo-Lion Cabinet Liaison'>  Leo/Leo-Lion Cabinet Liaison		</option>
                            <option value='Region Chairperson'> Region Chairperson		</option>
                            <option value='Zone Chairperson'>Zone Chairperson</option>
                            <option value='GAT Area Leader'>GAT Area Leader</option>
                            <option value='LCIF Area Leader'>LCIF Area Leader</option>
                            <option value='CA Leader GAT'>CA Leader GAT</option>
                            <option value='CA Leader LCIF'>CA Leader LCIF</option>
                            <option value='Past President'>Past President</option>
                            <option value='Member President'>Member President</option>
                          </select>
                          <div>
                            <input placeholder='Year' style={{ marginLeft: 0, marginRight: 0 }} name='yearData' value={data.yearData} className='form-control' onChange={(e) => handelInputField(e, index)} />
                          </div>
                          <Button className='addInputField' style={{ textDecoration: 'none', cursor: 'pointer', marginLeft: 3, backgroundColor: 'rgb(53, 53, 111)' }} variant='contained' onClick={dynamicInput}>+</Button>
                        </div>
                      </>
                    )
                  })
                }
                <br />
                <Box className="d-flex" sx={{ marginBottom: 2 }} >
                  <Button type='submit' className='shadow' style={{ borderRadius: '0.001rem', width: '50%', marginRight: 3, backgroundColor: 'rgb(53, 53, 111)', color: 'white' }} >Submit</Button>
                  <Button onClick={() => handleClose2()} type='reset' className='shadow' style={{ borderRadius: '0.001rem', width: '50%', backgroundColor: 'red', color: 'white' }} >Cancel</Button>
                </Box>
              </form>
            </DialogContentText>
          </DialogContent>

        </Dialog>
      </div>

      <div>

        <Dialog
          open={open3}
          onClose={handleClose3}
          maxWidth="lg"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ width: '39rem' }} >
                <div className='row' >
                  <Typography style={{ color: 'rgb(53, 53, 111)', fontSize: 23, textAlign: 'center', fontWeight: 'bold' }} >Add Business Details</Typography>
                  <br />
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Company Name  <span style={{ color: 'red', fontSize: '1.2rem' }} >*</span></Typography>
                    <input type='text' onChange={(e) => {
                      set_name_of_company(e.target.value)
                      setBusinessVerError(false)
                    }} style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Name of the company/orgnizatioin' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Company/Orgnizatioin Logo <span style={{ color: 'red', fontSize: '1.2rem' }} >*</span></Typography>
                    <Form.Control onChange={(e) => {
                      setLogo(e.target.files[0])
                      setBusinessVerError(false)
                    }} type='file' style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Product Photo' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Designation <span style={{ color: 'red', fontSize: '1.2rem' }} >*</span></Typography>
                    <input onChange={(e) => {
                      setDesignation(e.target.value)
                      setBusinessVerError(false)
                    }} style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Designation' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Website</Typography>
                    <Form.Control onChange={(e) => {
                      setWebsite(e.target.value)
                      setBusinessVerError(false)
                    }} type='text' style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Website' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Official email <span style={{ color: 'red', fontSize: '1.2rem' }} >*</span></Typography>
                    <Form.Control onChange={(e) => {

                      setOfficialEmailId(e.target.value)
                      setBusinessVerError(false)

                    }} type='text' style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Official Email id' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Business/Profession <span style={{ color: 'red', fontSize: '1.2rem' }} >*</span></Typography>
                    <Form.Control onChange={(e) => {
                      setBussinessData(e.target.value)
                      setBusinessVerError(false)
                    }} type='text' style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Business Profession' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Product/Services in brief <span style={{ color: 'red', fontSize: '1.2rem' }} >*</span></Typography>
                    <Form.Control onChange={(e) => {
                      setBusinessVerError(false)
                      setProductServices(e.target.value)
                    }} type='text' style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Product/Services in brief' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Business Verticle <span style={{ color: 'red', fontSize: '1.2rem' }} >*</span></Typography>
                    <Form.Control onChange={(e) => {
                      setBusinessVerticle(e.target.value)
                      setBusinessVerError(false)
                    }} type='text' style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Business verticle' />
                  </div>
                  {
                    businessVerError && (
                      <div>
                        <br />
                        <br />
                        <Typography style={{ color: 'red', textAlign: 'center' }} >Please Enetr all required (*) fields </Typography>
                        <br />
                      </div>
                    )
                  }
                  <br />
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='p-3'>
            <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleClose3}>Cancel</Button>
            <Button onClick={() => businessVerrticaleValidation()} style={{ backgroundColor: 'rgb(53, 53, 111)', color: 'white' }} >Add Business</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>

        <Dialog
          open={open4}
          onClose={handleClose4}
          maxWidth="lg"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ width: '39rem' }} >
                <div className='row' >
                  <Typography style={{ color: 'rgb(53, 53, 111)', fontSize: 23, textAlign: 'center', fontWeight: 'bold' }} >Add Product</Typography>
                  <br />
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Product Name  <span style={{ color: 'red', fontSize: 16 }} >*</span></Typography>
                    <input value={pName || ""} onChange={(e) => {
                      setPName(e.target.value)
                      setProductError(false)
                    }} style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Product Name' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Product Price  <span style={{ color: 'red', fontSize: 16 }} >*</span></Typography>
                    <input value={pPrice || ""} type='number' onChange={(e) => {
                      setPPrice(e.target.value)
                      setProductError(false)
                    }} style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Product Price' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Product Description  <span style={{ color: 'red', fontSize: 16 }} >*</span></Typography>
                    <input value={pDesc || ""} onChange={(e) => {
                      setPDesc(e.target.value)
                      setProductError(false)
                    }} style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Product Description' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Product Photo  <span style={{ color: 'red', fontSize: 16 }} >*</span></Typography>
                    <Form.Control onChange={(e) => {
                      setProductError(false)
                      setPPhoto(e.target.files[0])
                    }} type='file' style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Product Photo' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Offer</Typography>
                    <Form.Control value={offer || ""} maxLength={2} onChange={(e) => setOffer(e.target.value)} type='text' style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Offer (%)' />
                  </div>
                  <div className='col-6 p-1' >
                    <Typography style={{ fontSize: 13, color: 'black' }} >Offer Validity</Typography>
                    <input type='date' max={disableDate()} value={offerValidity || ""} onChange={(e) => setOfferValidity(e.target.value)} style={{ paddingLeft: 10, height: '2.3rem', width: '100%', border: '1px solid lightgray', borderRadius: '0.2rem' }} placeholder='Product Photo' />
                  </div>
                  <br />

                  {
                    productError && (
                      <div>
                        <br />
                        <br />
                        <Typography style={{ color: 'red', textAlign: 'center' }} >Please Enetr all required (*) fields </Typography>
                        <br />
                      </div>
                    )
                  }

                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='p-3'>
            <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => {
              handleClose4()
              setPName("")
              setPPhoto("")
              setPDesc("")
              setOffer("")
              setPPrice("")
              setOfferValidity("")
            }}>Cancel</Button>

            {
              !isEdit && (
                <Button onClick={productValidation} style={{ backgroundColor: 'rgb(53, 53, 111)', color: 'white' }} >Add Product</Button>
              )
            }

            {
              isEdit && (
                <Button onClick={editCA} style={{ backgroundColor: 'rgb(53, 53, 111)', color: 'white' }} >Edit Product</Button>
              )
            }

          </DialogActions>
        </Dialog>
      </div>
      <div className='card' style={{ justifyContent: 'center', alignItems: 'center', padding: 10, border: 'none', marginTop: 4, backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
        <div className='card' style={{ width: '10rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
          <FaInstagram size={24} color='#fff'></FaInstagram>
          <FaFacebookF size={24} color='#fff'></FaFacebookF>
          <FaYoutubeSquare size={24} color='#fff'></FaYoutubeSquare>
          <FaTwitterSquare size={24} color='#fff'></FaTwitterSquare>
        </div>
        <div style={{ width: '13rem', backgroundColor: 'white', height: '0.14rem', marginTop: 10 }} >

        </div>
        <Typography style={{ marginBottom: 10, color: 'white', fontSize: 14 }}>@2023 Emedha technologies all right registered.</Typography>
      </div>
    </Box>
  );
}