import React, { useState } from 'react'
import { FaFacebook, FaFacebookF, FaInstagram, FaPhone, FaRupeeSign, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa'
import { Button, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import logo1 from '../../image/lionlogo.jpeg'
import logo2 from '../../image/lions_club.png'
import EmailIcon from '@mui/icons-material/Email';
import RegisterPageSlider from '../RegisterPageSlider';

const SetPassword = () => {

  const [password, setPassword] = useState('')
  const [cPassword, setCPassword] = useState('')

  const navigate = useNavigate()

  const passwordValidation = () => {
    if (password === '' || cPassword === '') {
      inputField()
    } else {
      if (password !== cPassword) {
        notMatched()
      } else {
        createAccount()
      }
    }
  }


  const createAccountAlert = () => {
    Swal.fire({
      title: "Susseccfull",
      text: "Your account created successfully",
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        navigate('/user-login')
      } else {
        navigate('/user-login')
      }
    })
  }

  const notMatched = () => {
    Swal.fire({

      title: "Warning",
      text: "Your confirm password not matched",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {

      if (result.value) {
        return
      }
    })
  }

  const inputField = () => {
    Swal.fire({
      title: "Warning",
      text: "Any input field can't be empty",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        return
      }
    })
  }

  const { email } = useParams()

  const createAccount = () => {
    axios.post('https://lionsapi.emedha.in/signup/create-account', {
      email: email,
      pass: password
    }).then((res) => {
      if (res.data.status === 200) {
        createAccountAlert()
      }
    })
  }

  return (
    <div className='card login-main-container' style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, borderRadius: '0.1rem', border: 'none' }}  >
      <div className='navbar3 card' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 5, paddingRight: 5, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', backgroundColor: 'rgb(53, 53, 111)' }} >
        <Typography style={{ fontSize: 13, color: 'white' }} ><EmailIcon style={{ marginTop: '-0.16rem' }} /> lionsclub@324@gmail.com</Typography>
        <Typography style={{ fontSize: 13, color: 'white' }} ><FaPhone style={{ marginTop: '-0.16rem' }} /> +91 99 9999 9999</Typography>
      </div>
      <div className='navbar3 card cont' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
        <img src={logo2} style={{ width: '56px' }} />
        <div>
          <center>
            <Typography style={{ fontSize: 21, color: 'rgb(53, 53, 111)', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>
          </center>
        </div>
        <img src={logo1} style={{ width: '56px' }} />
      </div>
      <div className='col-10 mx-auto card auth-form-container' style={{ border: 'none', marginTop: 61, borderRadius: '1.4rem', }}>
        <div className='row p-1' >

          <div className='col-11 col-md-7 col-lg-4 col-xl-5 mx-auto pb-5' style={{ marginTop: '-4rem' }} >
            <RegisterPageSlider />
          </div>
          <div className='col-11 col-md-7 col-lg-6 col-xl-5 mx-auto p-1 mt-5' >

            <Typography style={{ fontSize: 19, fontWeight: 'bold', color: 'rgb(53, 53, 111)', marginTop: 12, marginBottom: 3 }}>Welcome to Lions International !</Typography>
            <Typography style={{ fontSize: 13, color: 'rgb(53, 53, 111)', marginTop: 1, marginBottom: 17 }}>Please password set to your account and start the adventure</Typography>

            <label>Create Password <span style={{ color: 'red' }} >*</span></label>
            <input onChange={(e) => {
              setPassword(e.target.value)
            }} type='password' style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.4rem', width: '100%', marginBottom: 4, outline: 'none' }} placeholder='Enter your password' />
            <br />
            <br />
            <label>Confirm Password <span style={{ color: 'red' }} >*</span></label>
            <input onChange={(e) => {
              setCPassword(e.target.value)
            }} type='password' style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.4rem', width: '100%', marginBottom: 26, outline: 'none' }} placeholder='Enter your password' />

            <Button onClick={() => passwordValidation()} variant="contained" style={{ marginBottom: 35, width: '100%', backgroundColor: 'rgb(53, 53, 111)' }}>Submit</Button>
          </div>
        </div>
      </div>
      <div className='card' style={{ justifyContent: 'center', alignItems: 'center', padding: 10, border: 'none', marginTop: 28, backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
        <div className='card' style={{ width: '10rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
          <FaInstagram size={24} color='#fff'></FaInstagram>
          <FaFacebookF size={24} color='#fff'></FaFacebookF>
          <FaYoutubeSquare size={24} color='#fff'></FaYoutubeSquare>
          <FaTwitterSquare size={24} color='#fff'></FaTwitterSquare>
        </div>
        <div style={{ width: '13rem', backgroundColor: 'white', height: '0.14rem', marginTop: 10 }} >

        </div>
        <Typography style={{ marginBottom: 10, color: 'white', fontSize: 14 }}>@2023 Emedha technologies all right registered.</Typography>
      </div>
    </div>
  )
}

export default SetPassword