import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { FaFacebookF, FaInstagram, FaTwitterSquare, FaWhatsapp, FaYoutubeSquare, FaHome, FaShoppingCart, FaBars, FaDotCircle, FaRupeeSign } from 'react-icons/fa';
import { Box, Button, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import logo1 from '../../image/lionlogo.jpeg'
import logo2 from '../../image/lions_club.png'
import EmailIcon from '@mui/icons-material/Email';
import RegisterPageSlider from '../RegisterPageSlider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ReCAPTCHA from "react-google-recaptcha";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';




const UserSignup = () => {


  const [sitekey, setSitekey] = useState([])


  const siteKey = () => {
    axios.post("https://lionsapi.emedha.in/contact/site-key").then((res) => {
      setSitekey(res.data)
    })
  }

  useEffect(() => {
    siteKey()
  }, [])

  const [value, setValue] = useState(false)

  function onChange(value) {
    if (value) {
      setValue(true)
    } else {
      setValue(false)
    }
  }

  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [membershipNo, setMembershipNo] = useState("")
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')

  const signupInputField = () => {
    Swal.fire({
      title: "Warning",
      text: "Any required fields cant be empty",
      confirmButtonColor: "red",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        return
      }
    })
  }


  const [isEmail, setIsEmail] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const formValidation = () => {
    if (name === "" || (membershipNo === "" && isMember) || email === "" || mobile === "") {
      signupInputField()
    } else {
      agree()
    }
  }


  const sendEmailVerificationOtp = () => {
    setIsDisabled(true)
    axios.post('https://lionsapi.emedha.in/signup', {
      email,
      name,
      mobile,
      membershipNo
    }).then((res) => {
      if (res.data.status === 200) {
        createAccountTemp()
      } else {
        alert('Your are already register')
        setIsDisabled(false)
      }
    })
  }

  const createAccountTemp = () => {
    axios.post('https://lionsapi.emedha.in/signup/temp-create-account', {
      email,
      name,
      mobile,
      membershipNo
    }).then((res) => {
      if (res.data.status === 200) {
        setIsEmail(true)
        setIsDisabled(false)
        setOpen21(true)
      } else {
        alert('Your are already register')
      }
    })
  }

  const [isMember, setIsMember] = useState(true)

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  const [open21, setOpen21] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen21 = () => {
    setOpen21(true);
  };

  const handleClose21 = () => {
    setOpen21(false);
  };



  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 270 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div style={{ backgroundColor: 'rgb(53, 53, 111)', paddingTop: 10, paddingBottom: 15, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', paddingRight: 29, borderRadius: '0.0003rem' }} className="card"  >
        <img src={logo2} style={{ width: '54px', marginLeft: 14, marginTop: 10 }} />
        <Typography style={{ marginTop: 7 }} >Welcome, <br /> Lions International</Typography>
      </div>
      <Divider />

      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/')} >
            <FaHome style={{ marginRight: 10, color: 'rgb(53, 53, 111)' }} />
            <ListItemText style={{ color: 'rgb(53, 53, 111)' }} primary='Home' />
          </ListItemButton>
        </ListItem>
      </List>

      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/products')}>
            <FaShoppingCart style={{ marginRight: 10, color: 'rgb(53, 53, 111)' }} />
            <ListItemText style={{ color: 'rgb(53, 53, 111)' }} primary='Products' />
          </ListItemButton>
        </ListItem>
      </List>
      <div style={{ position: 'absolute', bottom: 0, width: '100%', borderTop: '1px solid rgb(53, 53, 111)', paddingTop: 10, paddingBottom: 23 }} >
        <Typography style={{ marginBottom: 0, color: 'rgb(53, 53, 111)', fontSize: 11.3, marginTop: 13, marginLeft: 4 }}>@2023 Emedha technologies all right registered.</Typography>
      </div>
    </Box>
  );

  const agree = () => {
    setOpen(true)
  }


  return (
    <div>
      <div className='navbar3 card' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 5, paddingRight: 5, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', backgroundColor: 'rgb(53, 53, 111)' }} >
        <Typography style={{ fontSize: 13, color: 'white' }} ><EmailIcon style={{ marginTop: '-0.16rem' }} /> lbn320c@gmail.com</Typography>

        <Typography className="d-none d-sm-flex" style={{ fontSize: 17, color: 'white', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>

        <a href="https://api.whatsapp.com/send?phone=+918187094345&text= Hi District 320c" target="_blank">
          <button style={{ border: 'none', background: 'rgb(20, 170, 20)', height: '1.8rem', width: '6.8rem', borderRadius: '0.2rem', color: 'white' }}><FaWhatsapp style={{ fontSize: '1rem', marginTop: '-0.12rem' }} /> Whatsapp</button>
        </a>
      </div>
      <div className='navbar3 card card d-none d-sm-flex' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
        <img src={logo2} style={{ width: '56px' }} />
        <div className="card d-none d-sm-flex" style={{ width: '70%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} >
          <div className="card" style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '15rem', border: 'none' }}>
            <Typography onClick={() => navigate('/')} style={{ color: 'rgb(53, 53, 111)', cursor: 'pointer' }}>
              Home
            </Typography>
            <Typography onClick={() => navigate('/products')} style={{ cursor: 'pointer', color: 'rgb(53, 53, 111)' }}>
              Products
            </Typography>
            <Typography onClick={() => navigate('/contact-us')} style={{ cursor: 'pointer', color: 'rgb(53, 53, 111)' }}>
              Contact US
            </Typography>
          </div>
          <button onClick={() => navigate('/user-login')} className="getButton1" >
            Login/Signup
          </button>

        </div>
        <img src={logo1} style={{ width: '56px' }} />
      </div>
      <div className='navbar3 card card d-sm-none d-flex' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >

        <React.Fragment>

          <FaBars onClick={toggleDrawer('left', true)} style={{ fontSize: '1.2rem', marginLeft: '0.22rem' }} />
          <SwipeableDrawer
            anchor={'left'}
            open={state["left"]}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
          >
            {list('left')}
          </SwipeableDrawer>
        </React.Fragment>
        <Typography className="text-center" style={{ fontSize: 17, color: 'rgb(53, 53, 111)', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>
        <img src={logo1} style={{ width: '36px', marginRight: '0.22rem' }} />
      </div>
      <div className='navbar3 card card d-sm-none d-flex' style={{ borderRadius: '0.0001rem', backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
        <button onClick={() => navigate('/contact-us')} className="getButton2 w-50" >
          Contact Us
        </button>
        <button onClick={() => navigate('/user-login')} className="getButton1 w-50" >
          Login/Signup
        </button>
      </div>
      {
        isMember && (
          <div className='col-11 mx-auto card auth-form-container' style={{ border: 'none', marginTop: 61, borderRadius: '1.4rem', }}>
            <div className='row p-1' style={{ display: 'flex', justifyContent: 'center' }} >
              <div className='col-11 col-md-7 col-lg-4 col-xl-5 mx-auto' style={{ marginTop: '-1.5rem' }} >
                <RegisterPageSlider />
              </div>

              <div className='col-11 col-md-7 col-lg-4 col-xl-5 mx-auto p-1' >

                <Typography style={{ fontSize: 19, fontWeight: 'bold', color: 'rgb(53, 53, 111)', marginTop: 12, marginBottom: 3 }}>Welcome to Lions International !</Typography>
                <Typography style={{ fontSize: 13, color: 'rgb(53, 53, 111)', marginTop: 1, marginBottom: 17 }}>Please sign-in to your account and start the adventure</Typography>
                <Box sx={{ marginBottom: 2 }} >
                  <Button onClick={() => {
                    setIsMember(true)
                  }} className='shadow' style={{ borderRadius: '0.001rem', background: isMember ? 'rgb(53, 53, 111)' : 'white', color: isMember ? 'white' : 'rgb(53, 53, 111)', width: '50%' }} >Member</Button>
                  <Button onClick={() => {
                    setIsMember(false)
                  }} className='shadow' style={{ borderRadius: '0.001rem', background: !isMember ? 'rgb(53, 53, 111)' : 'white', color: !isMember ? 'white' : 'rgb(53, 53, 111)', width: '50%' }} >Non Member</Button>
                </Box>
                <label>Full Name <span style={{ color: 'red' }} >*</span></label>
                <input onChange={(e) => {
                  setName(e.target.value)
                }} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your first name' />

                <label>Email <span style={{ color: 'red' }} >*</span></label>
                <input onChange={(e) => {
                  setEmail(e.target.value)
                }} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your email' />


                <label>Membership Number <span style={{ color: 'red' }} >*</span></label>
                <input onChange={(e) => {
                  setMembershipNo(e.target.value)
                }} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your membership No' />

                <label>Mobile <span style={{ color: 'red' }} >*</span></label>
                <input onChange={(e) => {
                  setMobile(e.target.value)
                }} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your Mobile' />

                <Link to='/forgot-password' style={{ textDecoration: 'none' }} ><Typography style={{ marginBottom: 50, marginBottom: 39, color: 'rgb(44, 53, 131)' }}>Forgot password ?</Typography></Link>

                {
                  sitekey.map((data) => {
                    return (
                      <>
                        <ReCAPTCHA
                          sitekey={data.sitekey}
                          onChange={onChange}
                        />,
                      </>
                    )
                  })
                }
                <br />
                {
                  isEmail && (
                    <div style={{ padding: '0.4rem', borderLeft: '4px solid green', backgroundColor: 'lightgray', marginBottom: 2 }} >
                      <Typography style={{ color: 'rgb(53, 53, 111)' }} >Please check your register email and follow given instruction to create your account</Typography>
                    </div>
                  )
                }

                <Button disabled={isDisabled} onClick={() => formValidation()} variant="contained" style={{ marginBottom: 35, width: '100%', backgroundColor: !isDisabled ? 'rgb(53, 53, 111)' : '' }}>Signup</Button>
                <div style={{ marginBottom: 10 }}>
                  <Link to='/user-login' style={{ textDecoration: 'none' }} ><Typography style={{ textAlign: 'center', color: 'gray', fontSize: 12 }}>You have laready an account? <span style={{ color: 'rgb(44, 53, 131)' }} >Login Now</span></Typography></Link>
                </div>
              </div>
            </div>

          </div>
        )
      }
      {
        !isMember && (
          <div className='col-11 mx-auto card auth-form-container' style={{ border: 'none', marginTop: 61, borderRadius: '1.4rem', }}>
            <div className='row p-1' >
              <div className='col-11 col-md-7 col-lg-4 col-xl-5 mx-auto' style={{ marginTop: '-3.3rem' }} >
                <RegisterPageSlider />
              </div>
              <div className=' col-11 col-md-7 col-lg-4 col-xl-5 mx-auto p-1' >
                <Typography style={{ fontSize: 19, fontWeight: 'bold', color: 'rgb(53, 53, 111)', marginTop: 12, marginBottom: 3 }}>Welcome to Lions International !</Typography>
                <Typography style={{ fontSize: 13, color: 'rgb(53, 53, 111)', marginTop: 1, marginBottom: 17 }}>Please sign-in to your account and start the adventure</Typography>
                <Box sx={{ marginBottom: 2 }} >
                  <Button onClick={() => {
                    setIsMember(true)
                  }} className='shadow' style={{ borderRadius: '0.001rem', background: isMember ? 'rgb(53, 53, 111)' : 'white', color: isMember ? 'white' : 'rgb(53, 53, 111)', width: '50%' }} >Member</Button>
                  <Button onClick={() => {
                    setIsMember(false)
                  }} className='shadow' style={{ borderRadius: '0.001rem', background: !isMember ? 'rgb(53, 53, 111)' : 'white', color: !isMember ? 'white' : 'rgb(53, 53, 111)', width: '50%' }} >Non Member</Button>
                </Box>
                <label>Full Name <span style={{ color: 'red' }} >*</span></label>
                <input onChange={(e) => {
                  setName(e.target.value)
                }} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your first name' />



                <label>Email <span style={{ color: 'red' }} >*</span></label>
                <input onChange={(e) => {
                  setEmail(e.target.value)
                }} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your email' />



                <label>Mobile <span style={{ color: 'red' }} >*</span></label>
                <input onChange={(e) => {
                  setMobile(e.target.value)
                }} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your Mobile' />

                <Link to='/forgot-password' style={{ textDecoration: 'none' }} ><Typography style={{ marginBottom: 50, marginBottom: 39, color: 'rgb(44, 53, 131)' }}>Forgot password ?</Typography></Link>
                {
                  sitekey.map((data) => {
                    return (
                      <>
                        <ReCAPTCHA
                          sitekey={data.sitekey}
                          onChange={onChange}
                        />,
                      </>
                    )
                  })
                }
                <br />
                {
                  isEmail && (
                    <div style={{ padding: '0.4rem', borderLeft: '3px solid green', backgroundColor: 'lightgray', marginBottom: 2 }} >
                      <Typography style={{ color: 'rgb(53, 53, 111)' }} >Please check your register email and follow given instruction to create your account</Typography>
                    </div>
                  )
                }

                <Button disabled={isDisabled} onClick={() => formValidation()} variant="contained" style={{ marginBottom: 35, width: '100%', backgroundColor: !isDisabled ? 'rgb(53, 53, 111)' : '' }}>Signup</Button>
                <div style={{ marginBottom: 10 }}>
                  <Link to='/user-login' style={{ textDecoration: 'none' }} ><Typography style={{ textAlign: 'center', color: 'gray', fontSize: 12 }}>You have laready an account? <span style={{ color: 'rgb(44, 53, 131)' }} >Login Now</span></Typography></Link>
                </div>
              </div>
            </div>

          </div>
        )
      }
      <div>
        <Dialog
          open={open}

          aria-labelledby="alert-dialog-title"
          maxWidth='lg'
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div style={{ width: '64rem' }} >
              <Typography style={{ fontSize: '1.3rem', fontWeight: 'bold', color: 'rgb(53, 53, 111)' }} >
                Disclaimer
                <div style={{ borderTop: '1.6px solid rgb(53, 53, 111)', width: '2.6rem', marginTop: '-0.2rem' }} ></div>
              </Typography>
              <br />
              <Typography style={{ color: 'black', fontSize: '0.87rem' }}>
                Welcome to  <a href='https://lbn320c.com' target='_blank'>www.lbn320c.com</a> ("the Website"). By using this Website, you agree to comply with and be bound by the following disclaimer. If you do not agree with any part of this disclaimer, please do not continue using the Website.
                <br />
                <FaDotCircle style={{ fontSize: '0.6rem' }} /> Informational Purposes Only: The content and information provided on this Website are for general informational purposes only. While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the Website's content for any purpose. Any reliance you place on such information is strictly at your own risk.
                <br />
                <FaDotCircle style={{ fontSize: '0.6rem' }} /> User-Generated Content:  <a href='https://lbn320c.com' target='_blank'>www.lbn320c.com</a> may contain user-generated content such as posts, comments, messages, profiles, and other contributions from its users. We do not endorse or guarantee the accuracy, integrity, or quality of user-generated content. Users are solely responsible for their contributions, and any reliance on such content is at the user's own risk.
                <br />
                <FaDotCircle style={{ fontSize: '0.6rem' }} /> No Professional Advice: The content and information provided on this Website do not constitute professional advice of any kind. If you require specific advice related to business, legal, financial, or any other professional matter, please consult a qualified professional in the relevant field.
                <br />
                <FaDotCircle style={{ fontSize: '0.6rem' }} /> Third-Party Links: The Website may contain links to third-party websites or services for your convenience. We do not control or endorse the content, products, services, or practices of any third-party websites. Visiting any third-party websites through links on this Website is at your own risk, and we recommend reviewing their terms of use and privacy policies.
                <br />
                <FaDotCircle style={{ fontSize: '0.6rem' }} /> No Guarantees:  <a href='https://lbn320c.com' target='_blank'>www.lbn320c.com</a> does not guarantee any specific results, outcomes, or benefits from using the Website. Your success in networking or any business endeavors is dependent on various factors beyond our control.
                <br />
                <FaDotCircle style={{ fontSize: '0.6rem' }} /> Security and Privacy: While we implement reasonable security measures to protect user data, we cannot guarantee the security and confidentiality of information transmitted over the internet. Please review our Privacy Policy for more information on data collection, usage, and storage.
                <br />
                <FaDotCircle style={{ fontSize: '0.6rem' }} /> Changes to Disclaimer:  <a href='https://lbn320c.com' target='_blank'>www.lbn320c.com</a> reserves the right to modify or update this disclaimer at any time without prior notice. Please review this page periodically to stay informed about any changes.
                <br />
                <FaDotCircle style={{ fontSize: '0.6rem' }} /> Acceptance of Terms: By using  <a href='https://lbn320c.com' target='_blank'>www.lbn320c.com</a>, you acknowledge that you have read, understood, and agreed to this disclaimer.
                If you have any questions or concerns about this disclaimer, please contact us at lbn320c@gmail.com
              </Typography>
              <br />
              <br />
              <Typography style={{ color: 'black', fontSize: '0.87rem' }} >
                Last updated: 21-07-2023
                <Typography style={{ color: 'black', fontSize: '0.87rem' }} >Thank you for using  <a href='https://lbn320c.com' target='_blank'>www.lbn320c.com</a></Typography>
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              handleClose()
              sendEmailVerificationOtp()
            }} autoFocus>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className='card' style={{ justifyContent: 'center', alignItems: 'center', padding: 10, border: 'none', marginTop: 28, backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
        <div className='card' style={{ width: '10rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
          <FaInstagram size={24} color='#fff'></FaInstagram>
          <FaFacebookF size={24} color='#fff'></FaFacebookF>
          <FaYoutubeSquare size={24} color='#fff'></FaYoutubeSquare>
          <FaTwitterSquare size={24} color='#fff'></FaTwitterSquare>
        </div>
        <div style={{ width: '13rem', backgroundColor: 'white', height: '0.14rem', marginTop: 10 }} >

        </div>
        <Typography style={{ marginBottom: 10, color: 'white', fontSize: 14 }}>@2023 Emedha technologies all right registered.</Typography>
      </div>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open21}
          maxWidth='lg'
          onClose={handleClose21}
          aria-labelledby="responsive-dialog-title"
        >

          <DialogContent className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '30rem', overflow: 'hidden', width: '32rem', border: 'None' }}>
            <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '30rem', overflow: 'hidden', width: '32rem', border: 'None' }}>
              <img style={{ width: '800px', marginTop: '21rem' }} src={require('../../image/qr.jpg')} />
            </div>
            <Typography style={{ color: 'rgb(53, 53, 111)' }}>Non Lion members Subscribe : <FaRupeeSign style={{ color: "rgb(53, 53, 111)" }} /> 1000 /-</Typography>
            <Typography style={{ color: 'rgb(53, 53, 111)' }}>Lion members Subscribe : <FaRupeeSign style={{ color: "rgb(53, 53, 111)" }} /> 500 /-</Typography>
          </DialogContent>
          <DialogActions>

            <Button onClick={() => {
              handleClose21()
            }} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default UserSignup;