import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmailIcon from '@mui/icons-material/Email';
import { FaBars, FaFacebookF, FaHome, FaInstagram, FaShoppingCart, FaTwitterSquare, FaWhatsapp, FaYoutubeSquare } from "react-icons/fa";
import logo1 from '../../image/lionlogo.jpeg'
import logo2 from '../../image/lions_club.png'
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText'
import { Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {

    const [sitekey, setSitekey] = useState([])



    const siteKey = () => {
        axios.post("https://lionsapi.emedha.in/contact/site-key").then((res) => {
            setSitekey(res.data)
        })
    }

    useEffect(() => {
        siteKey()
    }, [])


    const [value, setValue] = useState(false)

    function onChange(value) {
        if (value) {
            setValue(true)
        } else {
            setValue(false)
        }
    }


    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [mobile, setMobile] = useState("")
    const [subject, setSubject] = useState("")


    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const messageFailedAlert = () => {
        Swal.fire({

            title: "Warning !",
            text: "Can't be empty any required * field",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok"
        }).then((result) => {
            if (result.value) {
                return
            }
        })
    }
    const messageSentAlert = () => {
        Swal.fire({
            title: "Successfull !",
            text: "Your message sent successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok"
        }).then((result) => {
            if (result.value) {
                return
            }
        })
    }

    const messageSentError = () => {
        Swal.fire({
            title: "Warning !",
            text: "Somthing went wrong please try again later",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok"
        }).then((result) => {
            if (result.value) {
                return
            }
        })
    }

    const messageSentConfirmation = () => {
        Swal.fire({
            title: "Warning !",
            text: "Are sure to send the message ?",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                sentMessage()
            } else {
                return
            }
        })
    }

    const sentMessage = () => {
        axios.post('https://lionsapi.emedha.in/contact', {
            email,
            mobile,
            message,
            subject,
            name
        }).then((res) => {
            if (res.data.status === 200) {
                messageSentAlert()
            } else {
                messageSentError()
            }
        })
    }

    const formValidation = () => {
        if (mobile === "" || message === "" || subject === "" || name === "" || email === "") {
            messageFailedAlert()
        } else {
            messageSentConfirmation()
        }
    }

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 270 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div style={{ backgroundColor: 'rgb(53, 53, 111)', paddingTop: 10, paddingBottom: 15, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', paddingRight: 29, borderRadius: '0.0003rem' }} className="card"  >
                <img src={logo2} style={{ width: '54px', marginLeft: 14, marginTop: 10 }} />
                <Typography style={{ marginTop: 7 }} >Welcome, <br /> Lions International</Typography>
            </div>
            <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate('/')} >
                        <FaHome style={{ marginRight: 10, color: 'rgb(53, 53, 111)' }} />
                        <ListItemText style={{ color: 'rgb(53, 53, 111)' }} primary='Home' />
                    </ListItemButton>
                </ListItem>
            </List>

            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate('/products')}>
                        <FaShoppingCart style={{ marginRight: 10, color: 'rgb(53, 53, 111)' }} />
                        <ListItemText style={{ color: 'rgb(53, 53, 111)' }} primary='Products' />
                    </ListItemButton>
                </ListItem>
            </List>
            <div style={{ position: 'absolute', bottom: 0, width: '100%', borderTop: '1px solid rgb(53, 53, 111)', paddingTop: 10, paddingBottom: 23 }} >
                <Typography style={{ marginBottom: 0, color: 'rgb(53, 53, 111)', fontSize: 11.3, marginTop: 13, marginLeft: 4 }}>@2023 Emedha technologies all right registered.</Typography>
            </div>
        </Box>
    );


    return (
        <div>
            <div className='navbar3 card' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 5, paddingRight: 5, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', backgroundColor: 'rgb(53, 53, 111)' }} >
                <Typography style={{ fontSize: 13, color: 'white' }} ><EmailIcon style={{ marginTop: '-0.16rem' }} />  lbn320c@gmail.com</Typography>

                <Typography className="d-none d-sm-flex" style={{ fontSize: 17, color: 'white', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>

                <a href="https://api.whatsapp.com/send?phone=+918187094345&text= Hi District 320c" target="_blank">
                    <button style={{ border: 'none', background: 'rgb(20, 170, 20)', height: '1.8rem', width: '6.8rem', borderRadius: '0.2rem', color: 'white' }}><FaWhatsapp style={{ fontSize: '1rem', marginTop: '-0.12rem' }} /> Whatsapp</button>
                </a>
            </div>
            <div className='navbar3 card card d-none d-sm-flex' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
                <img src={logo2} style={{ width: '56px' }} />
                <div className="card d-none d-sm-flex" style={{ width: '70%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} >
                    <div className="card" style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '15rem', border: 'none' }}>
                        <Typography onClick={() => navigate('/')} style={{ color: 'rgb(53, 53, 111)', cursor: 'pointer' }}>
                            Home
                        </Typography>
                        <Typography onClick={() => navigate('/products')} style={{ color: 'rgb(53, 53, 111)', cursor: 'pointer' }}>
                            Products
                        </Typography>
                        <Typography style={{ color: 'white', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.9rem', padding: 3, paddingLeft: 12, paddingRight: 12 }}>
                            Contact US
                        </Typography>
                    </div>

                    <button onClick={() => navigate('/user-login')} className="getButton1" >
                        Login/Signup
                    </button>

                </div>
                <img src={logo1} style={{ width: '56px' }} />
            </div>
            <div className='navbar3 card card d-sm-none d-flex' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >

                <React.Fragment>

                    <FaBars onClick={toggleDrawer('left', true)} style={{ fontSize: '1.2rem', marginLeft: '0.22rem' }} />
                    <SwipeableDrawer
                        anchor={'left'}
                        open={state["left"]}
                        onClose={toggleDrawer('left', false)}
                        onOpen={toggleDrawer('left', true)}
                    >
                        {list('left')}
                    </SwipeableDrawer>
                </React.Fragment>
                <Typography className="text-center" style={{ fontSize: 17, color: 'rgb(53, 53, 111)', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>
                <img src={logo1} style={{ width: '36px', marginRight: '0.22rem' }} />
            </div>
            <div className='navbar3 card card d-sm-none d-flex' style={{ borderRadius: '0.0001rem', backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
                <button className="getButton2 w-50" >
                    Contact Us
                </button>
                <button onClick={() => navigate('/user-login')} className="getButton1 w-50" >
                    Login/Signup
                </button>
            </div>

            <div className='col-11 mx-auto card auth-form-container' style={{ border: 'none', marginTop: 61, borderRadius: '1.4rem', }}>
                <div className='row p-1' >
                    <div className='col-11 mx-auto p-3 pb-2' >
                        <Typography style={{ fontSize: '1.6rem', fontWeight: 'bold', color: 'rgb(53, 53, 111)', marginTop: '0.1rem' }} >
                            GET IN TOUCH
                            <div style={{ borderTop: '4px solid rgb(53, 53, 111)', width: '2.6rem', marginTop: '-0.2rem' }} ></div>
                        </Typography>
                        <br />

                        <div className="row" >
                            <div className="col-6" >
                                <label>Name <span style={{ color: 'red' }} >*</span></label>
                                <input onChange={(e) => setName(e.target.value)} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your name' />
                            </div>
                            <div className="col-6" >
                                <label> Subject <span style={{ color: 'red' }} >*</span></label>
                                <input onChange={(e) => setSubject(e.target.value)} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your subject' />
                            </div>
                            <div className="col-6" >
                                <label>Mobile <span style={{ color: 'red' }} >*</span></label>
                                <input onChange={(e) => setMobile(e.target.value)} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your mobile' />
                            </div>
                            <div className="col-6" >
                                <label>Email <span style={{ color: 'red' }} >*</span></label>
                                <input onChange={(e) => setEmail(e.target.value)} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Enter your mobile' />
                            </div>
                            <div className="col-6" >
                                <label>Message <span style={{ color: 'red' }} >*</span></label>
                                <textarea onChange={(e) => setMessage(e.target.value)} rows={4} style={{ paddingLeft: 10, border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 5, outline: 'none' }} placeholder='Message ...' />
                            </div>
                        </div>

                        <br />
                        {
                            sitekey.map((data) => {
                                return (
                                    <>
                                        <ReCAPTCHA
                                            sitekey={data.sitekey}
                                            onChange={onChange}
                                        />,
                                    </>
                                )
                            })
                        }
                        <br />
                        <Button onClick={() => formValidation()} variant="contained" style={{ marginBottom: 35, width: '7rem', backgroundColor: 'rgb(53, 53, 111)', color: 'white' }}>Contact</Button>
                    </div>
                </div>
            </div>
            <div className="col-11 mx-auto card auth-form-container mt-2" style={{ borderRadius: '0.9rem', padding: 7 }} >
                <div class="mapouter"><div class="gmap_canvas"><iframe style={{ width: '100%', height: '70vh', borderRadius: '0.9rem' }} class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=GLCT Skill Development and Women Empowerment Centre, Plot no: 679 & 680Part, BN Reddy Nagar, B N Reddy Nagar, Cherlapalli, Secunderabad, Telangana 501301&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div></div>
            </div>
            <div className='card' style={{ justifyContent: 'center', alignItems: 'center', padding: 10, border: 'none', marginTop: 28, backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
                <div className='card' style={{ width: '10rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
                    <FaInstagram size={24} color='#fff'></FaInstagram>
                    <FaFacebookF size={24} color='#fff'></FaFacebookF>
                    <FaYoutubeSquare size={24} color='#fff'></FaYoutubeSquare>
                    <FaTwitterSquare size={24} color='#fff'></FaTwitterSquare>
                </div>
                <div style={{ width: '13rem', backgroundColor: 'white', height: '0.14rem', marginTop: 10 }} >
                </div>
                <Typography style={{ marginBottom: 10, color: 'white', fontSize: 14 }}>@2023 Emedha technologies all right registered.</Typography>
            </div>
        </div>
    )
}

export default ContactUs