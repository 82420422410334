import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { FaCalendar, FaCalendarAlt, FaDraftingCompass, FaFacebook, FaFacebookF, FaIdBadge, FaIdCard, FaIdCardAlt, FaInstagram, FaMobile, FaPlusCircle, FaProductHunt, FaRupeeSign, FaSellcast, FaTwitter, FaTwitterSquare, FaUser, FaUserAlt, FaWeebly, FaWhatsapp, FaYoutube, FaYoutubeSquare } from 'react-icons/fa';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import logo1 from '../../image/lionlogo.jpeg'
import logo2 from '../../image/lions_club.png'
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'Auto',
  color: theme.palette.text.secondary,
}));



export default function FullWidthGrid() {


  const { emailIdeParams } = useParams()


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileData, setProfileData] = React.useState([])
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const profile = (email = emailIdeParams) => {
    axios.post('https://lionsapi.emedha.in/profile', {
      email
    }).then((res) => {
      setProfileData(res.data)
    })
  }

  const [lionsData, setLionsData] = React.useState([])
  const [isLions, setIsLions] = React.useState(true)
  const [isBusiness, setIsBusiness] = React.useState(true)
  const [isLionsMem, setIsLionsMem] = React.useState(true)

  const lions_data_show = (email = emailIdeParams) => {
    axios.post('https://lionsapi.emedha.in/profile/lions_data/show', {
      email
    }).then((res) => {
      setLionsData(res.data)
      if (res.data[0].joined_year === null) {
        setIsLions(false)

      }
      if (res.data[0].name_of_company === null) {
        setIsBusiness(false)
      }

      if (res.data[0].membershipNo === "") {
        setIsLionsMem(false)
      } else {
        setIsLionsMem(true)
      }

    })
  }

  React.useEffect(() => {
    lions_data_show()
  }, [])

  React.useEffect(() => {
    profile()
  }, [])

  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const [productData, setProductData] = React.useState([])

  const productShow = () => {
    axios.post('https://lionsapi.emedha.in/product/show', {
      req_email_id: emailIdeParams
    }).then((res) => {
      setProductData(res.data)
    })
  }

  React.useEffect(() => {
    productShow()
  }, [])

  const navigate = useNavigate()

  React.useEffect(() => {
    if (!sessionStorage.getItem('userEmail')) {
      navigate('/')
    }
  }, [])


  const [profileSearchData, setProfileSearchData] = React.useState([])

  const searchProfile = (searchText) => {

    if (searchText === '') {
      setProfileSearchData([])
      return
    }
    axios.post('https://lionsapi.emedha.in/profile/profile-search', {
      searchText
    }).then((res) => {
      setProfileSearchData(res.data)
    })
  }

  const [customerEmail, setCustomerEmail] = useState()
  const [customerMobile, setCustomerMobile] = useState()
  const [name, setName] = useState()
  const [price, setPrice] = useState()
  const [product, setProdut] = useState()
  const [salerEmail, setSalerEmail] = useState()
  const [productName, setProductName] = useState()
  const [genPass, setGenPass] = useState(false)
  const [aboutProduct, setAboutProduct] = useState([])
  const [id, setId] = useState()

  const moreAboutProduct = (id) => {

    setId(id)

    axios.post('https://lionsapi.emedha.in/product/about-more', {
      id,
    }).then((res) => {
      setAboutProduct(res.data)
      handleClickOpen5()
      setProdut(res.data[0].productPhoto)
      setProductName(res.data[0].productName)
      setPrice(res.data[0].productPrice)
      setSalerEmail(res.data[0].UserId)
      profiles(res.data[0].UserId)
    })
  }

  const [isEmail, setIsEmail] = useState(false)

  const [open5, setOpen5] = React.useState(false);

  const handleClickOpen5 = () => {
    setOpen5(true);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };


  const [open6, setOpen6] = React.useState(false);

  const handleClickOpen6 = () => {
    setOpen6(true);
  };

  const handleClose6 = () => {
    setOpen6(false);
  };


  const [isRef, setIsRef] = useState(false)

  const productReferral = () => {

    setIsRef(true)
    axios
      .post('https://lionsapi.emedha.in/product/product-referral', {
        customerEmail: sessionStorage.getItem('guestLogin'),
        customerName: sessionStorage.getItem('customerName'),
        customerMobile: sessionStorage.getItem('customerMobile'),
        salerEmail: salerEmail,
        price,
        name: productName,
        product
      }).then((response) => {
        if (response.data.status === 200) {
          setIsRef(false)
          handleClose5()
          handleClose6()
          referralAlert()
        }
      })
  }

  const referralAlert = () => {
    Swal.fire({
      title: "Successfull !",
      text: "Referral sent successfully !",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.value) {
        moreAboutProduct(id)
      } else {
        moreAboutProduct(id)
      }
    })
  }

  const [salerMobile, setSalerMobile] = useState()
  const [salerName, setSalerName] = useState()

  const profiles = (email) => {
    axios.post('https://lionsapi.emedha.in/profile', {
      email
    }).then((res) => {
      setSalerName(res.data[0].name)
      setSalerMobile(res.data[0].mobile)
    })
  }

  return (
    <Box sx={{ flexGrow: 1 }}>

      <div className='navbar3 card' style={{ borderRadius: '0.0001rem', paddingTop: 1, backgroundColor: 'white', paddingBottom: 1, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', backgroundColor: 'rgb(53, 53, 111)' }} >
        <input onClick={() => handleClickOpen1()} style={{ paddingLeft: 10, height: '2.1rem', border: '0.8px solid lightgray', borderRadius: '1rem', width: '50%', outline: 'none', cursor: 'pointer' }} readOnly placeholder='Search profile' />
        <React.Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 42, height: 42 }}><FaUser size={26} /></Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => {
              handleClose()
              navigate(`/user-dashboard/${sessionStorage.getItem('userEmail')}`)
            }}>
              <Avatar /> My Profile
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon onClick={() => {
                sessionStorage.removeItem('email')
                navigate('/')
              }} >
                <Logout fontSize="small" />
              </ListItemIcon>
              <Typography onClick={() => {
                sessionStorage.removeItem('email')
                navigate('/')
              }} style={{ color: 'red' }} > Logout</Typography>
            </MenuItem>
          </Menu>
        </React.Fragment>
      </div>
      <div className='navbar3 card cont' style={{ borderRadius: '0.0001rem', paddingTop: 15, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 3, paddingRight: 3, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
        <img src={logo2} style={{ width: '56px' }} />
        <div>
          <center>
            <Typography style={{ fontSize: 21, color: 'rgb(53, 53, 111)', fontWeight: '500' }} >Lions Business Network- District 320C</Typography>
          </center>
        </div>
        <img src={logo1} style={{ width: '56px' }} />
      </div>

      <Grid container spacing={2} sx={{ p: 2 }} >
        <Grid item sm={12} md={4} sx={{ width: '100%' }} >
          <div className='profileContainer' >
            <Item style={{ height: '70VH', overflow: 'auto' }} elevation={0} >
              {
                profileData.map((data) => {

                  return (
                    <div>
                      <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none' }} >
                        <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Basic Information</Typography>
                      </div>
                      <div style={{ borderBottom: '1px solid rgb(53, 53, 111)', marginBottom: 10 }} >
                        <img src='https://th.bing.com/th/id/OIP.audMX4ZGbvT2_GJTx2c4GgHaHw?w=180&h=188&c=7&r=0&o=5&dpr=2.5&pid=1.7' style={{ width: '100px' }} />
                      </div>
                      <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaIdBadge style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Membership No:  {isLionsMem ? `${data.membershipNo}` : "Non-Lion Member"}</Typography>
                      <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaUserAlt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Name: {data.name}</Typography>
                      <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaMobile style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Mobile: {data.mobile}</Typography>
                      <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><EmailIcon style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Email: {data.email}</Typography>
                      <div className='card' style={{ border: 'none', height: '5rem', width: '100%', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.01rem', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', paddingLeft: 27, paddingRight: 27, marginTop: 32 }} >
                        <div className='card' style={{ height: 38, width: 38, borderRadius: 19, justifyContent: 'center', alignItems: 'center', border: '2px solid white', backgroundColor: 'rgb(53, 53, 111)' }} >
                          <FaInstagram style={{ color: 'white', fontSize: 22 }} ></FaInstagram>
                        </div>
                        <div className='card' style={{ height: 38, width: 38, borderRadius: 19, justifyContent: 'center', alignItems: 'center', border: '2px solid white', backgroundColor: 'rgb(53, 53, 111)' }} >
                          <FaTwitter style={{ color: 'white', fontSize: 22 }} ></FaTwitter>
                        </div>
                        <div className='card' style={{ height: 38, width: 38, borderRadius: 19, justifyContent: 'center', alignItems: 'center', border: '2px solid white', backgroundColor: 'rgb(53, 53, 111)' }} >
                          <FaFacebook style={{ color: 'white', fontSize: 22 }} ></FaFacebook>
                        </div>
                        <div className='card' style={{ height: 38, width: 38, borderRadius: 19, justifyContent: 'center', alignItems: 'center', border: '2px solid white', backgroundColor: 'rgb(53, 53, 111)' }} >
                          <FaYoutube style={{ color: 'white', fontSize: 22 }} ></FaYoutube>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </Item>
          </div>
        </Grid>
        <Grid item sm={12} md={4} sx={{ width: '100%' }}>
          <div className='profileContainer' >
            <Item elevation={0} style={{ height: '70vh' }} >
              <div>
                {
                  lionsData.map((data) => {
                    return (
                      <div>
                        <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none', width: '100%' }} >
                          <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Lions Information</Typography>
                        </div>

                        {
                          isLions && (
                            <div>
                              <div style={{ borderBottom: '1px solid rgb(53, 53, 111)', marginBottom: 10 }} >
                                <img src='https://th.bing.com/th/id/OIP._LZ97WsRvlwvwWTh7P4fUAAAAA?w=170&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' style={{ width: '100px' }} />
                              </div>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaCalendar style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Joined Year: {data.joined_year}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaIdCardAlt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Position: {data.position}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaCalendarAlt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Position Year: {data.year}</Typography>
                            </div>
                          )
                        }
                      </div>
                    )
                  })
                }
              </div>
              {
                !isLions && (
                  <center style={{ marginTop: 120 }} >
                    <img src='https://th.bing.com/th?id=OIP.SrVms3SPznlyIx8y39Pt8AHaHq&w=245&h=254&c=8&rs=1&qlt=90&o=6&dpr=2.5&pid=3.1&rm=2' style={{ width: '35px' }} />
                    <Typography style={{ width: '60%', textAlign: 'center', color: 'black' }} >If You are a lions member add lions data to improve your profile</Typography>
                    <Button disabled={true} variant='contained' ><FaPlusCircle />  <span style={{ marginLeft: 3 }} >Add</span></Button>
                  </center>
                )
              }
            </Item>
          </div>
        </Grid>
        <Grid item sm={12} md={4} sx={{ width: '100%' }}>
          <div className='profileContainer' >
            <Item elevation={0} style={{ height: '70vh', overflow: 'auto' }} >
              <div>
                {
                  lionsData.map((data) => {
                    return (
                      <div>
                        <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none' }} >
                          <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Business Information</Typography>
                        </div>

                        {
                          isBusiness && (
                            <div>
                              <div style={{ borderBottom: '1px solid rgb(53, 53, 111)', marginBottom: 10 }} >
                                <img src={data.logo} style={{ width: '100px' }} />
                              </div>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaDraftingCompass style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Name Of Company: {data.name_of_company}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaIdCard style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Designation: {data.designation}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaWeebly style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Website: {data.website}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><EmailIcon style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Email Id: {data.official_email_id}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaProductHunt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Product: {data.product}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaProductHunt style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Business/Profession: {data.businessProfession}</Typography>
                              <Typography style={{ padding: 10.2, marginBottom: 20, backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)' }} ><FaSellcast style={{ color: 'rgb(53, 53, 111)', fontSize: 14, marginTop: '-0.1rem' }} /> Business Vertical: {data.businessVerticalData}</Typography>
                            </div>
                          )
                        }
                      </div>
                    )
                  })
                }
              </div>


              {
                !isBusiness && (
                  <center style={{ marginTop: 120 }} >
                    <img src='https://th.bing.com/th?id=OIP.SrVms3SPznlyIx8y39Pt8AHaHq&w=245&h=254&c=8&rs=1&qlt=90&o=6&dpr=2.5&pid=3.1&rm=2' style={{ width: '35px' }} />
                    <Typography style={{ width: '60%', textAlign: 'center', color: 'black' }} >If You have a business add your business data to improve your profile</Typography>
                    <Button disabled={true} variant='contained' ><FaPlusCircle />  <span style={{ marginLeft: 3 }} >Add</span></Button>
                  </center>
                )
              }

            </Item>
          </div>
        </Grid>
        <Grid item md={12}>
          <div className='profileContainer' >
            <Item elevation={0} style={{ height: productData.length === 0 ? '70vh' : '', overflow: 'auto' }} >
              <div className='card' style={{ justifyContent: 'center', alignItems: 'center', height: '2.4rem', borderRadius: '0.01rem', backgroundColor: 'rgb(53, 53, 111)', border: 'none' }} >
                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} >Add Your Products</Typography>
              </div>

              <div className='card' style={{ border: 'none', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', paddingLeft: 14, paddingRight: 14, marginBottom: 23 }} >
                <Typography style={{ fontSize: '1.9rem', fontWeight: 'bold', color: 'rgb(53, 53, 111)' }} >
                  All Products
                  <div style={{ borderTop: '4px solid rgb(53, 53, 111)', width: '2.6rem', marginTop: '-0.2rem' }} ></div>
                </Typography>
                {
                  productData.length !== 0 && (
                    <center style={{ marginTop: 10, marginBottom: 10 }} >
                      <Button style={{ backgroundColor: 'lightgray', color: 'white' }} ><FaPlusCircle />  <span style={{ marginLeft: 3 }} >Add</span></Button>
                    </center>
                  )
                }
              </div>


              <div className='container-fluid pb-2' >
                <div className='row' >
                  {
                    productData.map((data) => {
                      return (
                        <div className="col-12 col-md-10 col-lg-3">
                          <article class="card1 card " style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ height: '24rem', overflow: 'hidden' }} >
                              <img
                                class="card1__background"
                                src={data.productPhoto}
                                alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                              />
                            </div>
                            <div class="card1__content | flow" style={{ width: '19.4rem' }} >
                              <div class="card1__content--container | flow">
                                <h2 class="card1__title" style={{ fontSize: '1.5rem' }} >{data.productName}</h2>
                                <p style={{ fontSize: 13 }}>Price: <FaRupeeSign />{data.productPrice - data.offer / 100 * data.productPrice} <del style={{ fontSize: 12, position: 'relative', top: -5 }}>{data.productPrice}</del> <span style={{ fontSize: 15, fontWeight: 'bold', color: 'blue' }} > Off {data.offer} %</span> </p>
                                <p>Offer Validity: {data.validity}</p>
                                <p style={{ color: 'white', fontWeight: 'bold' }} >  </p>
                                <p class="card1__description">
                                  {data.productDesc}
                                </p>
                              </div>
                              <button class="card1__button" style={{ borderRadius: '0.2rem' }} onClick={() => moreAboutProduct(data.id)}>More About</button>
                            </div>
                          </article>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              {
                productData.length === 0 && (
                  <center style={{ marginTop: 120 }} >
                    <img src='https://th.bing.com/th?id=OIP.SrVms3SPznlyIx8y39Pt8AHaHq&w=245&h=254&c=8&rs=1&qlt=90&o=6&dpr=2.5&pid=3.1&rm=2' style={{ width: '35px' }} />
                    <Typography style={{ width: '60%', textAlign: 'center', color: 'black' }} >If You have product data add and improve your profile</Typography>
                    <Button disabled={true} variant='contained' ><FaPlusCircle />  <span style={{ marginLeft: 3 }} >Add</span></Button>
                  </center>
                )
              }
            </Item>
          </div>
        </Grid>
      </Grid>
      <div>
        <Dialog
          open={open1}
          onClose={handleClose1}
          aria-labelledby="alert-dialog-title"
          maxWidth='lg'
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ width: '32rem', height: '35rem' }} >
            <Typography style={{ fontSize: '1.2rem', color: 'rgb(53, 53, 111)' }} >Search Profile</Typography>
            <br />
            <TextField onChange={(e) => searchProfile(e.target.value)} id="standard-basic" label="Search" variant="standard" autoFocus style={{ width: '100%' }} />
            <br />
            <br />
            {
              profileSearchData.length === 0 && (
                <center style={{ marginTop: 43 }} >
                  <img src='https://th.bing.com/th?id=OIP.SrVms3SPznlyIx8y39Pt8AHaHq&w=245&h=254&c=8&rs=1&qlt=90&o=6&dpr=2.5&pid=3.1&rm=2' style={{ width: '35px' }} />
                  <Typography style={{ color: 'black' }}>Search profile to explore data</Typography>
                </center>
              )
            }
            {
              profileSearchData.map((data) => {
                return (
                  <Box>
                    <Box onClick={() => {
                      navigate(`/user-profile/${data.email}`)
                      window.location.reload()
                    }} sx={{ width: '100%', padding: '0.4rem', backgroundColor: 'lightgray', borderLeft: '2px solid rgb(53, 53, 111)', color: 'rgb(53, 53, 111)', marginBottom: 1.2, cursor: 'pointer' }} >
                      <Typography style={{ color: 'rgb(53, 53, 111)' }} >{data.name}, {data.name_of_company}</Typography>
                    </Box>
                  </Box>
                )
              })
            }
          </DialogContent>
          <DialogActions>
            <Button style={{ backgroundColor: 'rgb(53, 53, 111)' }} variant='contained' onClick={handleClose1} autoFocus>
              Cancel
            </Button>

          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={open5}
          onClose={handleClose5}
          aria-labelledby="alert-dialog-title"
          maxWidth='xl'
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
          </DialogTitle>
          <DialogContent style={{ width: '78rem', height: '43rem' }} >
            <div className="row" >
              {
                aboutProduct.map((data) => {
                  return (
                    <div className="row">
                      <div className="col-lg-6 ">
                        <div style={{ borderRight: '1.7px solid lightgray', padding: 23, padding: 67, borderTop: '1.7px solid lightgray', borderTopRightRadius: '0.2rem' }} >
                          <img src={data.productPhoto} style={{ width: '100%' }} />
                        </div>
                      </div>
                      <div className="col-lg-6" style={{ marginTop: '-0.4rem' }} >
                        <Typography style={{ color: 'rgb(53, 53, 111)', fontSize: 23, fontWeight: 'bold' }} >Product Details</Typography>
                        <div style={{ borderTop: '4px solid rgb(53, 53, 111)', width: '4rem', marginTop: '-0.2rem' }} ></div>
                        <br />
                        <div>
                          <Typography style={{ fontSize: 19, fontWeight: 'bold', color: 'black' }}>Product: {data.productName}</Typography>
                          <p style={{ fontSize: 13, color: 'black' }}>Price: <FaRupeeSign />{data.productPrice - data.offer / 100 * data.productPrice} <del style={{ fontSize: 12, position: 'relative', top: -5 }}>{data.productPrice}</del> <span style={{ fontSize: 15, fontWeight: 'bold', color: 'blue' }} > Off {data.offer} %</span> </p>
                          <br />
                          <Typography style={{ fontSize: 14, color: 'black' }}>Validity: {data.validity}</Typography>
                          <br />
                          <Typography style={{ fontSize: 14, color: 'black' }}>Description: {data.productDesc}</Typography>
                          <br />
                          <Typography style={{ color: 'rgb(53, 53, 111)', fontSize: 18, fontWeight: 'bold' }} >Referral</Typography>
                          <div style={{ borderTop: '4px solid rgb(53, 53, 111)', width: '2.3rem', marginTop: '-0.2rem' }} ></div>
                          <br />
                          <button onClick={() => {
                            productReferral()
                          }} disabled={isRef} style={{ border: 'none', background: !isRef ? 'rgb(172, 55, 55)' : 'lightgray', height: '1.8rem', width: '6.8rem', borderRadius: '0.2rem', color: 'white', marginRight: 3 }}><EmailIcon style={{ fontSize: '1rem', marginTop: '-0.12rem' }} /> Email</button>

                          <a onClick={() => {
                            handleClose5()
                            handleClose6()

                          }} href={`https://api.whatsapp.com/send?phone=${salerMobile}&text= Dear ${salerName}, You have got a referral from ${sessionStorage.getItem('customerName')} product name ${productName} Product image link: ${product}, Customer Contact Information, Mobile Number: ${sessionStorage.getItem('customerMobile')} And Email ${sessionStorage.getItem('guestLogin')}`} target="_blank">
                            <button style={{ border: 'none', background: 'rgb(20, 170, 20)', height: '1.8rem', width: '6.8rem', borderRadius: '0.2rem', color: 'white' }}><FaWhatsapp style={{ fontSize: '1rem', marginTop: '-0.12rem' }} /> Whatsapp</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <div style={{ borderTop: '1.6px solid lightgray', marginTop: '0.4rem' }} ></div>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose5} style={{ backgroundColor: 'red', color: 'white', fontSize: '0.8rem' }}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={open6}
          onClose={handleClose6}
          maxWidth='lg'
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ padding: 10 }}>
            <Typography style={{ fontSize: 17, fontWeight: 'bold', color: 'rgb(53, 53, 111)', marginBottom: 12, marginTop: 17 }} >Send Referral</Typography>
            <br />

            {
              isEmail && (
                <div style={{ width: '34rem' }} >
                  <input onChange={(e) => setName(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter Your name" />
                  <br></br>
                  <br />
                  <input onChange={(e) => setCustomerEmail(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter your email" />
                  <br></br>
                  <br />
                  <input onChange={(e) => setCustomerMobile(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter your mobile" />
                  <br></br>
                  <br />
                </div>
              )
            }

            {
              !isEmail && (
                <div style={{ width: '34rem' }} >
                  <input onChange={(e) => setName(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter Your name" />
                  <br></br>
                  <br />
                  <input onChange={(e) => setCustomerEmail(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter your email" />
                  <br></br>
                  <br />
                  <input onChange={(e) => setCustomerMobile(e.target.value)} style={{ height: '2.4rem', border: '1px solid lightgray', borderRadius: '0.2rem', outline: 'none', width: '100%', paddingLeft: 12 }} placeholder="Enter your mobile" />
                  <br></br>
                  <br />
                </div>
              )
            }

          </div>
          {
            isEmail && (
              <DialogActions>
                <Button style={{ background: 'red', color: 'white' }} onClick={handleClose6}>Cancel</Button>
                <Button disabled={isRef} onClick={() => productReferral()} style={{ background: !isRef ? 'rgb(53, 53, 111)' : 'lightgray', color: 'white' }} autoFocus>
                  Send
                </Button>
              </DialogActions>
            )
          }

          {
            !isEmail && (
              <DialogActions>
                <Button style={{ background: 'red', color: 'white' }} onClick={handleClose6}>Cancel</Button>
                <a style={{ marginLeft: '0.2rem' }} onClick={() => {
                  handleClose6()
                  handleClose5()
                }} href={`https://api.whatsapp.com/send?phone=${salerMobile}&text= Dear ${salerName}, You have got a referral from ${name} product name ${productName} Product image link: ${product}, Customer Contact Information, Mobile Number: ${customerMobile} And Email ${customerEmail}`} target="_blank">
                  <Button disabled={isRef} style={{ background: !isRef ? 'rgb(53, 53, 111)' : 'lightgray', color: 'white' }} autoFocus>
                    Send
                  </Button>
                </a>
              </DialogActions>
            )
          }
        </Dialog>
      </div>
      <div className='card' style={{ justifyContent: 'center', alignItems: 'center', padding: 10, border: 'none', marginTop: 4, backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
        <div className='card' style={{ width: '10rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none', backgroundColor: 'rgb(53, 53, 111)', borderRadius: '0.02rem' }}>
          <FaInstagram size={24} color='#fff'></FaInstagram>
          <FaFacebookF size={24} color='#fff'></FaFacebookF>
          <FaYoutubeSquare size={24} color='#fff'></FaYoutubeSquare>
          <FaTwitterSquare size={24} color='#fff'></FaTwitterSquare>
        </div>
        <div style={{ width: '13rem', backgroundColor: 'white', height: '0.14rem', marginTop: 10 }} >

        </div>
        <Typography style={{ marginBottom: 10, color: 'white', fontSize: 14 }}>@2023 Emedha technologies all right registered.</Typography>
      </div>
    </Box>
  );
}