import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.css'



const RegisterPageSlider = () => {

    const settings = {
        dots: false,
        slidesToShow: 1, 
        slidesToScroll: 1,
        speed: 680,
        autoplay: true,
    }

    return (

        <center>
            < Slider style={{ marginTop: '-2rem', marginLeft:10}} {...settings}>

                <center>
                    <img className='login-page-slider-img23' src={require('../../image/image1.png')} />
                </center>


                <center>
                    <img className='login-page-slider-img23' src={require('../../image/image2.png')} />
                </center>

                <center>
                    <img className='login-page-slider-img23' src={require('../../image/image3.png')} />
                </center>
                <center>
                    <img className='login-page-slider-img24' src={require('../../image/image5.png')} />
                </center>
            </Slider >
        </center>
    )
}

export default RegisterPageSlider